import {
  autoLoad,
  removeFromStorage,
  saveToStorage,
} from '@helpers/storage.helpers'
import { Store } from '@stores'
import { IUser } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { STORE_KEYS } from 'src/constants/keys.contants'

export class UserStore {
  [key: string]: any
  loading: boolean
  store: Store
  user: IUser
  constructor(store: Store) {
    makeAutoObservable(this)
    this.loading = false
    this.store = store
    this.user = {} as IUser
    autoLoad(this, STORE_KEYS.USER)
  }

  @computed
  get current_user(): IUser {
    return this.user
  }

  @computed
  get current_user_name(): IUser['name'] {
    return this.current_user?.name
  }

  @computed
  get current_user_initial(): string {
    return this.current_user_name.charAt(0) ?? ''
  }

  @action
  whoAmI = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const currentUser = await this.store.api.user.whoAmI()
      this.set(STORE_KEYS.USER, currentUser, true)
    } catch (e) {
      console.log('e', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  update = async (data: IUser) => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      // const res = await this.store.api.user.update(data)
      this.set(STORE_KEYS.USER, data, true)
      return Promise.resolve(data)
    } catch (e) {
      console.error('error: ', e)
      return Promise.reject(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  clearUser = () => {
    this.set(STORE_KEYS.USER, null)
    removeFromStorage(STORE_KEYS.USER)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
