import { PageWrapper } from '@components'
import { INVOICE_DEFAULT_VALUES, URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { ISingleDocument } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { generatePath, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'

import { UpdateInvoiceForm } from 'src/partials/index.partials'

export const InvoiceCreateView = observer(() => {
  const { ui, invoice, shared, client } = useStore()
  const navigate = useNavigate()

  const form = useForm({
    defaultValues: INVOICE_DEFAULT_VALUES,
  })

  const handleBack = () => navigate(URL_PATHS.INVOICE_LIST)

  const handleSuccess = useCallback((data: Partial<ISingleDocument>) => {
    const route = generatePath(URL_PATHS.INVOICE_DETAIL, { id: data.id })
    ui.handleToaster('Factuur aangemaakt', { type: 'success' })
    form.reset()
    return navigate(route)
  }, [])

  const handleErrors = (errors: Record<string, string[]>, message?: string) => {
    form.clearErrors()
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as keyof typeof INVOICE_DEFAULT_VALUES, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )

    if (message) toast(message, { type: 'error' })
  }

  const onSubmitCallback = async (values: FieldValues) => {
    try {
      const res = await invoice.create(values)
      handleSuccess(res)
    } catch (e: any) {
      handleErrors(e.errors, e.message)
    }
  }

  const handleSubmit = () => {
    form.handleSubmit(onSubmitCallback)()
  }

  useEffect(() => {
    invoice.reset(true)
    client.getAll()
    if (!shared.has_vat_codes) shared.getVatCodes()
  }, [])

  return (
    <OvFormProvider
      loading={invoice.loading}
      form={form}
      onSubmit={handleSubmit}>
      <PageWrapper
        backAction={handleBack}
        pageTitle="Nieuwe Factuur"
        actions={
          <Box
            display="flex"
            gap={2}>
            <Button
              endIcon={<Add />}
              variant="contained"
              onClick={handleSubmit}>
              {'Factuur Opslaan'}
            </Button>
          </Box>
        }>
        <UpdateInvoiceForm />
      </PageWrapper>
    </OvFormProvider>
  )
})
