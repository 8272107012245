import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { action, makeAutoObservable } from 'mobx'

const mockData = [
  {
    id: 'total',
    label: 'Totaal',
    revenueExVat: 98999.9,
    vat: 19384.9,
    invoices: 244,
    costs: 13904.34,
  },
  {
    id: 'q1',
    label: 'Q1 t/m maa',
    revenueExVat: 34954.98,
    vat: 7340.54,
    invoices: 57,
    costs: 3304.34,
  },
  {
    id: 'q2',
    label: 'Q2 t/m jun',
    revenueExVat: 25938.9,
    vat: 6100.9,
    invoices: 45,
    costs: 2873.5,
  },
  {
    id: 'q3',
    label: 'Q3 t/m sep',
    revenueExVat: 29048.4,
    vat: 3364.2,
    invoices: 49,
    costs: 2385.34,
  },
  {
    id: 'q4',
    label: 'Q4 t/m dec',
    revenueExVat: 17999.9,
    vat: 1384.9,
    invoices: 22,
    costs: 4567.34,
  },
]

export class DashboardStore {
  [key: string]: any
  store: Store
  data: any
  loading: boolean

  constructor(store: Store) {
    this.store = store
    this.data = mockData
    this.loading = true
    makeAutoObservable(this)
  }

  @action
  getData = () => {
    this.set(STORE_KEYS.LOADING, true)
    this.set(STORE_KEYS.LOADING, false)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
