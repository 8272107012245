import {
  MenuDrawer,
  MenuDrawerMobile,
  OutvoiceQuidAddMenu,
  OvErrorBoundary,
  OvModal,
  RequireAuth,
} from '@components'
import { URL_PATHS } from '@constants'
import { useIsMobile } from '@hooks/useIsMobile'
import { useStore } from '@hooks/useStore'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export const DefaultWithHeader = observer(() => {
  const { ui } = useStore()
  const isMobile = useIsMobile()
  const location = useLocation()

  const margin = useMemo(() => {
    if (isMobile) return '0'
    return ui.menuOpen ? '320px' : '64px'
  }, [ui.menuOpen, isMobile])

  if (location.pathname === URL_PATHS.ROOT)
    return <Navigate to={URL_PATHS.DASHBOARD} />

  return (
    <RequireAuth>
      <>
        <main
          style={{ paddingLeft: margin, transition: 'padding-left .25s ease' }}>
          <OvErrorBoundary>
            <Outlet />
          </OvErrorBoundary>
        </main>
        {isMobile ? <MenuDrawerMobile /> : <MenuDrawer />}
        <OutvoiceQuidAddMenu />
        <OvModal />
      </>
    </RequireAuth>
  )
})
