import {
  OvForm,
  OvFormInput,
  OvFormInputReadOnly,
  OvFormProvider,
} from '@components'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ISingleClientContact } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { ClientContactUpdateForm } from './client.contact.update.form'
import { useParams } from 'react-router-dom'

interface ITableColumns<T> {
  label: string
  key: keyof T
  align: 'left' | 'right'
}

export const ClientContactForm = observer(() => {
  const { ui, client } = useStore()
  const { id } = useParams()

  if (!id) {
    return null
  }
  const _id = parseInt(id)

  const contactTableColumns: ITableColumns<ISingleClientContact>[] = [
    {
      label: 'Voornaam',
      key: 'first_name',
      align: 'left',
    },
    {
      label: 'Achternaam',
      key: 'last_name',
      align: 'left',
    },
    {
      label: 'E-mail',
      key: 'email',
      align: 'left',
    },
    {
      label: 'Telefoonnummer',
      key: 'phone',
      align: 'left',
    },
    {
      label: 'Afdeling',
      key: 'department',
      align: 'left',
    },
  ]

  const handleEditContact = async (id: ISingleClientContact['id']) => {
    await ui
      .showModal({
        title: 'Wijzig contactpersoon',
        body: () => <ClientContactUpdateForm contactId={id} />,
      })
      .catch(() => void 0)
  }

  const handleDeleteContact = async (
    id: ISingleClientContact['id'],
    name: string
  ) =>
    ui
      .confirm({
        title: LABELS.CONFIRM_REMOVE_CONTACT_TITLE,
        content: DYNAMIC_LABELS.CONFIRM_REMOVE_CONTACT(name),
        withCancel: true,
      })
      .then(() => client.removeContact(id).catch(e => ui.handleErrorToast(e)))
      .catch(e => void 0)

  const renderTableBody = useMemo(
    () =>
      client.current_client_contacts?.map((singleContact, index) => {
        return (
          <TableRow key={`${singleContact.id}`}>
            {contactTableColumns.map(({ key, align }, _index) => (
              <TableCell
                key={`${singleContact.id}-${_index}-${index}`}
                align={align}>
                <OvFormInputReadOnly
                  label=""
                  value={singleContact[key]}
                />
              </TableCell>
            ))}
            <TableCell>
              <Stack
                direction="row"
                spacing={1}>
                <IconButton
                  onClick={() => handleEditContact(singleContact.id)}
                  size="small">
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() =>
                    handleDeleteContact(
                      singleContact.id,
                      `${singleContact.first_name} ${singleContact.last_name}`
                    )
                  }
                  size="small">
                  <Delete />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
        )
      }),
    [client.current_client_contacts]
  )

  const ContactForm = useMemo(() => {
    const form = useForm({
      defaultValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        department: '',
      },
      mode: 'onBlur',
    })

    const onSubmitCallBack = useCallback(
      (data: FieldValues) => {
        client
          .addContact(data as ISingleClientContact)
          .then(() => {
            ui.modal?.resolve?.(data)
          })
          .catch(e => console.log(e))
      },
      [ui.modal]
    )

    const handleSubmit = () => form.handleSubmit(onSubmitCallBack)()
    const FormInput = OvFormInput<ISingleClientContact>

    useEffect(() => {
      if (!client.clientContacts.length) client.getClientContacts()
    }, [])

    return (
      <OvFormProvider
        form={form}
        onSubmit={handleSubmit}
        loading={client.loading}>
        <OvForm>
          <Stack
            direction="column"
            spacing={2}>
            <FormInput
              name="first_name"
              label="Voornaam"
              rules={{
                required: 'Voornaam is verplicht',
              }}
            />
            <FormInput
              name="last_name"
              label="Achternaam"
              rules={{ required: 'Achternaam is verplicht' }}
            />
            <FormInput
              name="email"
              label="E-mail"
              rules={{
                required: 'E-mail is verplicht',
              }}
            />
            <FormInput
              name="phone"
              label="Telefoonnummer"
            />
            <FormInput
              name="department"
              label="Afdeling"
            />
            <Stack
              direction="row"
              spacing={2}>
              <Button
                onClick={() => ui.modal.reject?.()}
                variant="outlined">
                Annuleren
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained">
                Toevoegen
              </Button>
            </Stack>
          </Stack>
        </OvForm>
      </OvFormProvider>
    )
  }, [])

  const handleAddRow = async () => {
    try {
      await ui
        .showModal({
          title: 'Nieuwe contactpersoon',
          body: () => ContactForm,
          resolve: data => console.log('Resolve: ', data),
        })
        .catch(e => void 0)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Card sx={{ p: 2 }}>
      {' '}
      <TableContainer sx={{ height: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {contactTableColumns.map(singleColumn => (
                <TableCell
                  key={singleColumn.key}
                  align={singleColumn.align}>
                  {singleColumn.label}
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody}</TableBody>
        </Table>
        <Box
          display="flex"
          mt={2}>
          <Button
            variant="text"
            onClick={handleAddRow}
            endIcon={<Add />}>
            Contact toevoegen
          </Button>
        </Box>
      </TableContainer>
    </Card>
  )
})
