import { OvCard } from '@components'
import { useStore } from '@hooks/useStore'
import { Box, Button, Card, TextField } from '@mui/material'
import { IUser } from '@typings'
import { observer } from 'mobx-react-lite'
import { FieldValues, useForm } from 'react-hook-form'

export const ProfileForm = observer(() => {
  const { user } = useStore()

  console.log('UserPRofile: ', user.current_user)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      // dateOfBirth: user.current_user?.profile?.dateOfBirth,
      name: user.current_user?.name,
      email: user.current_user?.email,
    },
  })

  const onSubmit = (values: FieldValues) => {
    console.log('values: ', values)
    user.update(values as IUser)
  }

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data))}>
      <OvCard>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <TextField
            label="Naam"
            {...register('name')}
            type="text"
            required
          />
          <TextField
            label="Email"
            {...register('email')}
            type="email"
            required
          />
        </Box>
        <Button
          sx={{ marginTop: '1rem' }}
          variant="contained"
          type="submit">
          Opslaan
        </Button>
      </OvCard>
    </form>
  )
})
