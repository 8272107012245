import { NEW_ENDPOINTS } from '@constants'
import { IVatCode } from '@typings'
import { AxiosResponse } from 'axios'
import { BaseApi } from './_base.api'

export class SharedApi extends BaseApi {
  getVatCodes = () =>
    this.api
      .get(NEW_ENDPOINTS.SHARED.VAT)
      .then((res: AxiosResponse<{ data: IVatCode[] }>) => res.data.data)

  getCountries = () =>
    this.api
      .get(NEW_ENDPOINTS.SHARED.COUNTRIES, { params: { per_page: 250 } })
      .then(res => res.data.data)
}
