import { PageWrapper } from '@components'
import { Box, Button, Grid, List, ListItem, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'

import styles from './home.module.scss'
import { toast } from 'react-toastify'
import { HOME_CONTENT } from '@constants'

export const HomeView = observer((): JSX.Element => {
  const PageHeader = () => {
    return (
      <div className={styles['home-header']}>
        <Box className={styles['home-header-box']}>
          <Typography variant="h1">{HOME_CONTENT.LEAD.TITLE}</Typography>
          <Typography variant="subtitle1">
            {HOME_CONTENT.LEAD.SUB_TITLE}
          </Typography>
        </Box>
      </div>
    )
  }

  return (
    <PageWrapper beforeInner={<PageHeader />}>
      <section>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}>
            <Typography variant="h2">{HOME_CONTENT.SECTION_1.TITLE}</Typography>
            <Typography>{HOME_CONTENT.SECTION_1.CONTENT}</Typography>
          </Grid>
        </Grid>
      </section>
      <section>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}>
            <Typography variant="h2">{HOME_CONTENT.USP.TITLE}</Typography>
            <List>
              {HOME_CONTENT.USP.CONTENT.map((singleContent, i) => (
                <ListItem key={i}>
                  <Typography variant="h5">{singleContent.TERM}</Typography>
                  <Typography>{singleContent.EXPLAINER}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </section>
      <section>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}>
            <Typography variant="h2">
              {HOME_CONTENT.HOW_IT_WORKS.TITLE}
            </Typography>
            <List>
              {HOME_CONTENT.HOW_IT_WORKS.CONTENT.map((singleContent, i) => (
                <ListItem key={i}>
                  <Typography variant="h5">{singleContent.STEP}</Typography>
                  <Typography>{singleContent.EXPLAINER}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </section>
      <section>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}>
            <Typography variant="h2">{HOME_CONTENT.ABOUT.TITLE}</Typography>
            <Typography>{HOME_CONTENT.ABOUT.CONTENT}</Typography>
            <Typography variant="h2">
              {HOME_CONTENT.ABOUT.WHY.TITLE}{' '}
            </Typography>
            <List>
              {HOME_CONTENT.ABOUT.WHY.CONTENT.map((singleContent, i) => (
                <ListItem key={i}>
                  <Typography variant="h5">{singleContent.TERM}</Typography>
                  <Typography>{singleContent.EXPLAINER}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </section>
    </PageWrapper>
  )
})
