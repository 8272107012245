/* eslint-disable max-len */
export const HOME_CONTENT = {
  LEAD: {
    TITLE: 'Outvoice',
    SUB_TITLE: 'Vereenvoudig Uw Bedrijfsadministratie',
  },
  SECTION_1: {
    TITLE: 'Focus op ondernemen',
    CONTENT:
      'Outvoice is dé oplossing voor ondernemers zoals u, die hun bedrijfsadministratie moeiteloos willen beheren en gestructureerd willen overdragen aan hun accountant. Met onze gebruiksvriendelijke online software wordt uw administratie een fluitje van een cent.',
  },
  USP: {
    TITLE: 'Wat Outvoice doet',
    CONTENT: [
      {
        TERM: 'Eenvoudige Administratie',
        EXPLAINER:
          'Beheer uw facturen, kosten en belastingen moeiteloos op één plek.',
      },
      {
        TERM: 'Soepele Samenwerking',
        EXPLAINER:
          'Werk naadloos samen met uw accountant, bespaar tijd en kosten.',
      },
      {
        TERM: 'Volledig Fiscaal Compliant',
        EXPLAINER:
          'Onze software is afgestemd op het Nederlandse belastingstelsel.',
      },
    ],
  },
  HOW_IT_WORKS: {
    TITLE: 'Ontdek hoe Outvoice u kan helpen uw bedrijf soepeler te runnen.',
    SUB_TITLE: 'Hoe het werkt',
    CONTENT: [
      {
        STEP: 'Registreer Uw Bedrijf',
        EXPLAINER: 'Maak uw account aan en voer uw bedrijfsgegevens in.',
      },
      {
        STEP: 'Beheer Uw Administratie',
        EXPLAINER: 'Facturen, onkosten, belastingen - beheer alles met gemak.',
      },
      {
        STEP: 'Deel Met Uw Accountant',
        EXPLAINER:
          'Uw accountant krijgt toegang tot uw gegevens voor snelle en nauwkeurige rapportage.',
      },
      {
        STEP: 'Profiteer Van Efficiency',
        EXPLAINER: 'Bespaar tijd, geld en focus op uw onderneming.',
      },
    ],
  },
  ABOUT: {
    TITLE: 'Uw Partner in Administratieve Vereenvoudiging',
    CONTENT:
      'Bij Outvoice begrijpen we de uitdagingen waarmee ondernemers worden geconfronteerd bij het beheren van hun administratie. Onze missie is om u te helpen uw bedrijfsadministratie te vereenvoudigen en uw accountant de tools te geven die hij of zij nodig heeft voor succes.',
    WHY: {
      TITLE: 'Waarom Outvoice?',
      CONTENT: [
        {
          TERM: 'Betrouwbaarheid',
          EXPLAINER: 'We zijn er voor u, elke stap van de weg.',
        },
        {
          TERM: 'Eenvoud',
          EXPLAINER:
            'Onze software is ontworpen met de gebruiker in gedachten.',
        },
        {
          TERM: 'Nauwkeurigheid',
          EXPLAINER: 'U kunt vertrouwen op onze fiscaal compliant oplossing.',
        },
      ],
    },
  },
}
