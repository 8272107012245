import { useStore } from '@hooks/useStore'
import { Box, Button, TextField, Typography } from '@mui/material'
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridPaginationModel,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues } from 'react-hook-form'

let timer: NodeJS.Timeout

export const FindCompanyForm = observer(
  ({ callBack }: { callBack: (selectedRow: any) => void }) => {
    const { kvk, ui } = useStore()
    const [rowCountState, setRowCountState] = useState(0)
    const [selectedRow, setSelectedRow] = useState(null)

    const onSubmit = (values: FieldValues) => {
      // company.createCompany(values as ICompany)
    }

    const handleSearch = (value: string) => {
      clearTimeout(timer)
      timer = setTimeout(() => kvk.handleSearchTerm(value), 500)
      return timer
    }

    const tableColumns: DataGridProps['columns'] = [
      {
        field: 'handelsnaam',
        type: 'string',
        headerName: 'Handelsnaam',
        flex: 1,
      },
      {
        field: 'kvkNummer',
        headerName: 'KVK nummer',
        flex: 1,
      },
      {
        field: 'plaats',
        type: 'string',
        headerName: 'Plaats',
        flex: 1,
      },
      // {
      //   field: 'vestigingsnummer',
      //   headerName: 'Vestigingsnummer',
      //   flex: 1,
      // },
    ]

    const handleRowClick = ({ row }: GridRowParams) => setSelectedRow(row)
    const handleCallBack = () => {
      callBack(selectedRow)
      ui.toggleModal()
    }

    const handlePaginationModel = useCallback(
      ({ page }: GridPaginationModel, details: GridCallbackDetails) => {
        if (page + 1 !== kvk.pagination.pagina) {
          kvk.handleNewPage(page + 1)
        }
      },
      [kvk.pagination]
    )

    useEffect(() => {
      setRowCountState((prevRowCountState: number) =>
        kvk.pagination.aantal !== undefined
          ? kvk.pagination?.aantal
          : prevRowCountState
      )
    }, [kvk.list, kvk.pagination?.aantal, setRowCountState])

    useEffect(() => {
      kvk.findCompany()
    }, [])

    console.log('kvk.list: ', kvk.list)
    return (
      <>
        <Box sx={{ marginBottom: 1 }}>
          <TextField
            type="search"
            label="Zoek op handelsnaam"
            onChange={e => handleSearch(e.target.value)}
            helperText={
              'U kunt hier rechtstreeks zoeken in het handelsregister'
            }
          />
        </Box>
        <Box sx={{ minHeight: '250px', maxHeight: '80vh' }}>
          <DataGrid
            autoHeight
            autoPageSize
            paginationModel={{
              page: kvk.pagination.pagina - 1 ?? 15,
              pageSize: kvk.pagination.aantal ?? 0,
            }}
            onPaginationModelChange={(model, details) =>
              handlePaginationModel(model, details)
            }
            loading={kvk.loading}
            columns={tableColumns}
            onRowClick={(row: GridRowParams) => handleRowClick(row)}
            rows={kvk.current_list}
            paginationMode="server"
            rowCount={kvk.pagination.totaal}
            getRowId={row => row.kvkNummer}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="outlined"
              onClick={() => ui.toggleModal()}>
              Annuleer
            </Button>
            <Button
              variant="contained"
              disabled={!selectedRow}
              onClick={handleCallBack}>
              Selecteer
            </Button>
          </Box>
        </Box>
      </>
    )
  }
)
