import { ENDPOINTS } from '@constants'
import { AxiosResponse } from 'axios'
import {
  IKvkSearchQueryParams,
  IKvkSearchResponse,
} from 'src/typings/kvk.typings'
import { BaseApi } from './_base.api'

export class KvkApi extends BaseApi {
  searchCompany = (params: Partial<IKvkSearchQueryParams>) =>
    this.api
      .get(ENDPOINTS.KVK.ALL, {
        params,
      })
      .then((res: AxiosResponse<{ data: IKvkSearchResponse }>) => res.data)
}
