import { OvInput, OvSelect } from '@components'
import { useStore } from '@hooks/useStore'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ICompanyInvoiceTask, ICompanyTask } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormContext, useWatch } from 'react-hook-form'
import { OvFormInput } from 'src/components/core/ov-form-input/ov-form-input'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'
import { OvForm } from 'src/components/core/ov-form/ov-form'

export const CreateInvoiceForm = observer(() => {
  const [selectedTasks, setSelectedTasks] = useState<ICompanyInvoiceTask[]>([])
  const form = useFormContext()
  const { client, company } = useStore()
  const tasks: ICompanyInvoiceTask[] = useWatch({
    control: form.control,
    name: 'invoiceTasks',
  })

  const taskForm = useForm<ICompanyInvoiceTask>({
    defaultValues: {
      label: '',
      price: 0,
      unit: '',
      vatCodeId: null,
      forCompany: false,
      quantity: 1,
    },
  })

  const handleTaskFormSubmitCallBack = (values: ICompanyInvoiceTask) => {
    const { forCompany, ...rest } = values

    if (forCompany) {
      console.log('create Task for company')
    }
    setSelectedTasks([...selectedTasks, { ...rest }])
    form.setValue('tasks', selectedTasks)
  }

  const handleAddTask = () => {
    if (selectedTasks) {
      form.setValue('tasks', [...tasks, selectedTasks])
    }
  }

  const updateTaskQuantity = useCallback(
    (task: ICompanyTask, qnty: ICompanyInvoiceTask['quantity']) => {
      console.log('qntyL ', qnty)
      const currentTasks = tasks
      const updatedTask = currentTasks.find(({ taskId }) => taskId === task.id)
      if (updatedTask) {
        updatedTask.price = task.price
        updatedTask.quantity = qnty
        console.log('currentTasks: ', currentTasks)
        form.setValue('tasks', currentTasks)
      }
    },
    [tasks]
  )

  const roundNumber = (number: number) =>
    Number((Math.round((number + Number.EPSILON) * 100) / 100).toFixed(2))

  const calculateTotalEX = useMemo(() => {
    let total = 0

    for (const task of tasks) {
      const taskTotal = roundNumber(task.quantity * task.price)
      total = roundNumber(total + taskTotal)
    }

    form.setValue('total', total)

    return total
  }, [tasks])

  const calculateTotalINC = useMemo(
    () => roundNumber(calculateTotalEX * 1.21),
    [calculateTotalEX]
  )

  const taskTableColumns = [
    {
      label: 'Taak',
      key: 'label',
      flex: 2,
    },
    {
      label: 'Prijs',
      key: 'price',
      flex: 1,
    },
    {
      label: 'Per',
      key: 'unit',
      flex: 1,
    },
  ]

  const renderTableData = useMemo(() => {
    return (
      <TableContainer sx={{ height: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {taskTableColumns.map(singleColumn => (
                <TableCell key={singleColumn.key}>
                  {singleColumn.label}
                </TableCell>
              ))}
              <TableCell>Aantal</TableCell>
              <TableCell>Sub Totaal</TableCell>
              <TableCell>BTW</TableCell>
              <TableCell>Totaal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map(({ taskId, quantity }) => {
              const currentTask = company.tasks.find(
                singleTask => singleTask.id == taskId
              )

              if (!currentTask) return

              return (
                <TableRow key={`${taskId}-${quantity}`}>
                  {taskTableColumns.map(({ key }) => (
                    <TableCell key={key}>
                      {currentTask ? currentTask[key] : '-'}
                    </TableCell>
                  ))}
                  <TableCell>
                    <OvInput
                      variant="outlined"
                      type="number"
                      value={quantity}
                      onChange={e =>
                        updateTaskQuantity(
                          currentTask,
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>
                    {currentTask?.price
                      ? `€ ${roundNumber(quantity * currentTask.price)}`
                      : '-'}
                  </TableCell>
                  <TableCell>{currentTask.vat_code.label}</TableCell>
                  <TableCell>
                    {currentTask?.price
                      ? `€ ${roundNumber(
                          quantity *
                            currentTask.price *
                            ((100 + currentTask.vat_code.percentage) / 100)
                        )}`
                      : '-'}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <OvFormProvider
                form={taskForm}
                onSubmit={() =>
                  taskForm.handleSubmit(handleTaskFormSubmitCallBack)
                }>
                <TableCell>
                  <OvFormInput
                    type="text"
                    name="label"
                    label="Naam"
                  />
                </TableCell>
                <TableCell>
                  <OvFormInput
                    name="price"
                    label="Prijs"
                  />
                </TableCell>
                <TableCell>
                  <OvFormInput
                    name="unit"
                    label="Per"
                  />
                </TableCell>
                <TableCell>
                  <OvFormInput
                    name="vatCodeId"
                    label="BTW Tarief"
                  />
                </TableCell>
                <TableCell>
                  <OvFormInput
                    name="quantity"
                    label="aantal"
                  />
                </TableCell>
                <TableCell>
                  <OvFormInput
                    name="forCompany"
                    label="Opslaan voor hergebruik?"
                  />
                </TableCell>
              </OvFormProvider>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Typography
                  fontWeight={'bold'}
                  color="black">
                  Totaal
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell>
                <Typography color="black">{`€ ${calculateTotalEX}`}</Typography>
              </TableCell>
              <TableCell />
              <TableCell>
                <Typography
                  color="primary"
                  fontWeight="bold">{`€ ${calculateTotalINC}`}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }, [company.tasks, tasks])

  useEffect(() => {
    client.getAll()
    company.getAllTasks()
  }, [])

  return (
    <>
      <OvForm>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <OvFormInput
            name="clientId"
            type="select"
            label="Kies Klant"
            options={client.list}
            valueKey="id"
            labelKey="name"
          />
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <OvSelect
              options={company.tasks}
              label="Kies taak"
              valueKey="id"
              labelKey="label"
              value={selectedTasks}
              output="option"
              onChange={(e: any) => {
                const { value } = e.target
                setSelectedTasks({
                  ...value,
                  quantity: 1,
                  price: value.price,
                })
              }}
              renderOption={value => `${value.label} (${value.unit})`}
            />
            <Button
              onClick={handleAddTask}
              variant="outlined">
              Add
            </Button>
          </Box> */}
          {renderTableData}
        </div>
      </OvForm>
    </>
  )
})
