import reportWebVitals from '@helpers/reportWebVitals.helper'
import { render } from 'preact'
import 'preact/debug'
import React from 'react'
import App from './App'

const root = document.getElementById('root') as HTMLElement

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
