import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  Fade,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import styles from './page-wrapper.module.scss'
import { ArrowBack } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

interface IPageWrapper {
  children: React.ReactNode
  background?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'primary-gradient'
    | 'secondary-gradient'
  beforeInner?: React.ReactNode
  pageTitle?: string
  loading?: boolean
  backAction?: () => void
  actions?: React.ReactNode
}

export const PageWrapper = ({
  children,
  background = 'default',
  beforeInner,
  backAction,
  pageTitle,
  loading,
  actions,
}: IPageWrapper) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const isXS = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const isMD = useMediaQuery((theme: any) =>
    theme.breakpoints.between('md', 'lg')
  )
  const isLG = useMediaQuery((theme: any) => theme.breakpoints.up('lg'))

  const getWrapperClasses = useMemo(
    () =>
      clsx(
        styles['page-wrapper'],
        background && styles[`page-wrapper--${background}`]
      ),
    [background]
  )

  const getWrapperInnerClasses = useMemo(
    () =>
      clsx(
        styles['page-wrapper-inner'],
        isXS && styles['page-wrapper-inner--xs'],
        isMD && styles['page-wrapper-inner--md'],
        isLG && styles['page-wrapper-inner--lg']
      ),
    [isXS, isMD, isLG]
  )

  const handleBackAction = useCallback(() => {
    if (state?.from) return navigate(-1)

    return backAction?.()
  }, [backAction, state])

  // console.log('render: ', window.location.href)

  return (
    <div className={getWrapperClasses}>
      {!loading && beforeInner && beforeInner}
      {/* <section className={getWrapperInnerClasses}> */}
      <Container
        maxWidth="xl"
        className={getWrapperInnerClasses}>
        {(pageTitle || backAction || actions) && (
          <div className={styles['page-wrapper-title']}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              justifyContent={{ md: 'space-between' }}
              gap={{ xs: 2, md: 1 }}
              flex={1}>
              <Stack
                direction={'row'}
                gap={{ xs: 1, sm: 2 }}
                flex={1}
                alignItems={'center'}>
                {backAction && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <IconButton
                      onClick={handleBackAction}
                      color="primary">
                      <ArrowBack fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
                {loading ? (
                  <Skeleton>
                    <div className={styles['page-wrapper-title']}>
                      <Typography variant="h1">{pageTitle}</Typography>
                    </div>
                  </Skeleton>
                ) : (
                  <Typography
                    variant="h1"
                    color="WindowText">
                    {pageTitle}
                  </Typography>
                )}
              </Stack>
              {actions && (
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  gap={1}
                  alignItems={{ xs: 'flex-start', md: 'center' }}>
                  {actions}
                </Stack>
              )}
            </Stack>
          </div>
        )}
        {loading ? (
          <Fade in={loading}>
            <Box
              sx={{
                display: 'flex',
                flex: '1 0 auto',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress sx={{ height: '100%' }} />
            </Box>
          </Fade>
        ) : (
          children
        )}
      </Container>
      {/* </section> */}
    </div>
  )
}
