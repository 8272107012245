import { PageWrapper } from '@components'
import { useStore } from '@hooks/useStore'
import { Box, Card, CardContent, Chip, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

export const DashboardView = observer(() => {
  const { user, company, dashboard } = useStore()
  const [selectedData, setSelectedData] = useState(dashboard.data[0])

  useEffect(() => {
    // user.whoAmI()
  }, [])

  return (
    <PageWrapper pageTitle={`Welkom ${user.current_user_name}`}>
      <Grid
        container
        spacing={2}
        columns={{ xs: 1, md: 2 }}>
        <Grid
          item
          xs={2}>
          <Typography variant="subtitle1">{company?.company?.name}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          gap={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                md: 'row',
              },
              gap: 2,
            }}>
            {dashboard.data.map((singleData: any) => (
              <Chip
                color={
                  selectedData.id === singleData.id ? 'primary' : 'default'
                }
                key={singleData.id}
                label={singleData.label}
                onClick={() => setSelectedData(singleData)}
              />
            ))}
          </Box>
        </Grid>
        <Grid
          item
          sm={1}>
          <Card elevation={1}>
            <CardContent>
              <Typography
                variant="h3"
                marginBottom={2}>
                Totale omzet (ex BTW)
              </Typography>
              <Typography
                variant="h2"
                color="primary"
                marginBottom={1}>
                {`€ ${selectedData.revenueExVat}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          sm={1}>
          <Card elevation={1}>
            <CardContent>
              <Typography
                variant="h3"
                marginBottom={2}>
                Totale BTW
              </Typography>
              <Typography
                marginBottom={1}
                variant="h2"
                color="primary">
                {`€ ${selectedData.vat}`}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          sm={1}>
          <Card elevation={1}>
            <CardContent>
              <Typography
                variant="h3"
                marginBottom={2}>
                Verstuurde facturen
              </Typography>
              <Typography
                variant="h2"
                marginBottom={1}
                color="primary">
                {selectedData.invoices}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageWrapper>
  )
})
