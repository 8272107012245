import { OvForm, OvFormInput, OvFormProvider } from '@components'
import { useStore } from '@hooks/useStore'
import { Button, Stack } from '@mui/material'
import { ISingleClientContact } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

export interface IClientContactUpdateForm {
  contactId: ISingleClientContact['id']
}

export const ClientContactUpdateForm = observer(
  ({ contactId }: IClientContactUpdateForm) => {
    const { client, ui } = useStore()
    const getDefaultValues = useCallback(
      () => client.getSingleClientContact(contactId),
      [contactId]
    )

    const form = useForm({
      defaultValues: getDefaultValues,
      mode: 'onBlur',
    })

    const onSubmitCallBack = useCallback(
      (data: FieldValues) => {
        client
          .updateContact(data as ISingleClientContact)
          .then(() => {
            ui.modal?.resolve?.(data)
          })
          .catch(e => console.log(e))
      },
      [ui.modal]
    )

    const handleSubmit = () => form.handleSubmit(onSubmitCallBack)()
    const FormInput = OvFormInput<ISingleClientContact>

    return (
      <OvFormProvider
        form={form}
        onSubmit={handleSubmit}>
        <OvForm>
          <Stack
            direction="column"
            spacing={2}>
            <FormInput
              name="first_name"
              label="Voornaam"
              rules={{
                required: 'Voornaam is verplicht',
              }}
            />
            <FormInput
              name="last_name"
              label="Achternaam"
              rules={{ required: 'Achternaam is verplicht' }}
            />
            <FormInput
              name="email"
              label="E-mail"
              rules={{
                required: 'E-mail is verplicht',
              }}
            />
            <FormInput
              name="phone"
              label="Telefoonnummer"
            />
            <FormInput
              name="department"
              label="Afdeling"
            />
            <Stack
              direction="row"
              spacing={2}>
              <Button
                onClick={() => ui.modal.reject?.()}
                variant="outlined">
                Annuleren
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained">
                Opslaan
              </Button>
            </Stack>
          </Stack>
        </OvForm>
      </OvFormProvider>
    )
  }
)
