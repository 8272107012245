import { ENDPOINTS } from '@constants'
import { IUser } from '@typings'
import { BaseApi } from '../api/_base.api'

export class UserApi extends BaseApi {
  whoAmI = () => this.api.get(ENDPOINTS.USER.WHO_AM_I).then(res => res.data)

  update = async (data: IUser) =>
    await this.api.put(ENDPOINTS.USER.UPDATE, data).then(res => res.data.data)
}
