import { formatColumnData } from '@helpers/formatColumnData.helper'
import { Delete } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  LinearProgress,
  useMediaQuery,
} from '@mui/material'
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import { DatagridStore } from '@stores/datagrid.stores'
import { IDatagridColumns, IDatagridResponsiveColumn } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo } from 'react'
import { DATA_GRID } from 'src/constants/components.constants'

interface IOvDatagridProps extends Partial<DataGridProps> {
  store: DatagridStore
  params?: Record<string, any>
  customColumns?: IDatagridResponsiveColumn
  responsiveColumns?: boolean
}

export const OvDataGrid = observer(
  ({
    store,
    onRowClick,
    params,
    customColumns,
    responsiveColumns,
    ...rest
  }: IOvDatagridProps) => {
    const isMobile = responsiveColumns
      ? useMediaQuery((theme: any) => theme.breakpoints.between('xs', 'md'))
      : false

    const _columns = useMemo(
      () => formatColumnData(store.columns),
      [store.columns]
    )

    const handleRowSelection = (
      model: GridRowSelectionModel,
      details: GridCallbackDetails
    ) => {
      store.handleSelection(model)
    }

    const removeDisabled = useMemo(() => {
      return store.selected.length === 0
    }, [store.selected])

    const enalbeAutoPageSize = useMemo(
      () => !!store.current_rows.length,
      [store.current_rows]
    )

    const handleRemoveSelection = () => {
      store.removeSelection()
    }

    useEffect(() => {
      store.getData(params, isMobile, customColumns)

      return () => store.reset()
    }, [params, isMobile, customColumns])

    return (
      <>
        <Box
          sx={{
            marginBottom: 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <Collapse in={!removeDisabled}>
            <ButtonGroup size="small">
              <Button
                onClick={handleRemoveSelection}
                variant="contained"
                color="secondary"
                disabled={removeDisabled}
                endIcon={<Delete />}>
                Verwijder selectie
              </Button>
            </ButtonGroup>
          </Collapse>
        </Box>
        <DataGrid
          {...DATA_GRID}
          loading={store.is_loading}
          columns={_columns}
          rowCount={store.current_pagination?.total}
          rows={store.current_rows}
          disableRowSelectionOnClick
          onRowClick={onRowClick}
          // onRowSelectionModelChange={handleRowSelection}
          disableColumnMenu
          checkboxSelection={false}
          onPaginationModelChange={model => console.log('model: ', model)}
          autoHeight
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: () => (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                Geen gegevens
              </Box>
            ),
            ...rest.slots,
          }}
          sx={({
            palette: {
              primary: { main },
              background: { paper },
            },
          }) => ({
            background: paper,
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: '700',
              color: main,
            },
            '.MuiTablePagination-displayedRows': {
              fontWeight: '700',
              color: main,
            },
            '.MuiDataGrid-overlayWrapperInner': {
              background: paper,
            },
            '.MuiDataGrid-row': {
              cursor: 'pointer',
            },
          })}
          paginationMode="client"
          {...rest}
        />
      </>
    )
  }
)
