import { URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Button, TextField } from '@mui/material'
import { IUser } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const SignupForm = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    watch,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  })
  const { auth } = useStore()
  const password = watch('password')
  const confirmPassword = watch('confirmPassword')
  const navigate = useNavigate()

  const onSubmit = (values: FieldValues) => {
    const redirectToDashboard = () => navigate(URL_PATHS.DASHBOARD)
    auth.signUp(values as IUser, redirectToDashboard)
  }

  const validated = useMemo(() => {
    console.log('dirtyFields: ', dirtyFields)
    console.log('dirtyFields: ', isDirty)
    if (!isDirty || !dirtyFields.password) return true

    if (isDirty && dirtyFields.confirmPassword) {
      return (
        !!(password?.length && confirmPassword?.length) && !!(password === confirmPassword)
      )
    }
  }, [password, confirmPassword, isDirty, dirtyFields])

  return (
    <form onSubmit={handleSubmit(data => onSubmit(data))}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <TextField
          label="Voornaam"
          {...register('firstName')}
          type="text"
          required
        />
        <TextField
          label="Achternaam"
          {...register('lastName')}
          type="text"
          required
        />
        <TextField
          label="Email"
          {...register('email')}
          type="email"
          required
        />
        <TextField
          label="Password"
          {...register('password')}
          type="password"
          required
        />
        <TextField
          label="Password again"
          id="confirmPassword"
          {...register('confirmPassword')}
          type="password"
          error={!validated}
          required
        />
      </div>
      <Button
        variant="contained"
        type="submit"
        sx={{ marginTop: '1.5rem' }}>
        Maak account
      </Button>
    </form>
  )
})
