import { OvFormProvider, PageWrapper } from '@components'
import { MY_COMPANY_TABS } from '@constants'
import { useStore } from '@hooks/useStore'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Stack, Tab } from '@mui/material'
import { ISingleCompany } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

export const MyCompanyView = observer(() => {
  const { company } = useStore()
  const [selectedTab, setSelectedTab] = useState(MY_COMPANY_TABS[0].value)

  const handleTabChange = (
    e: React.SyntheticEvent<Element, Event>,
    val: string
  ) => setSelectedTab(val)

  const getDefaultValues = async () => {
    return await company.getMyCompany()
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleSubmit = () => {
    form.handleSubmit(onSubmit)()
  }

  const handleSucces = (data: ISingleCompany) => {
    toast('Bedrijfsggegevens opgeslaggen', { type: 'success' })
    form.reset(data)
  }

  const handleError = (data: any) => console.log('ONerrors: ', data)

  const onSubmit = async (values: FieldValues) => {
    return await company
      .update(values)
      .then(result => handleSucces(result))
      .catch(errors => handleError(errors))
  }

  const renderCompanyView = useMemo(() => {
    return (
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange}>
          {MY_COMPANY_TABS.map(singleTab => (
            <Tab
              value={singleTab.value}
              label={singleTab.label}
              key={singleTab.value}
              disabled={singleTab.disabled}
            />
          ))}
        </TabList>
        <Box>
          {MY_COMPANY_TABS.map(singleTab => (
            <TabPanel
              sx={{ padding: '24px 0' }}
              value={singleTab.value}
              key={singleTab.value}>
              {singleTab.component ?? singleTab.label}
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    )
  }, [company.has_companies, company.current_company, selectedTab])

  return (
    <OvFormProvider
      form={form}
      onSubmit={() => onSubmit}
      loading={company.loading}>
      <PageWrapper pageTitle="Mijn onderneming">
        {renderCompanyView}
        <Stack
          direction="row"
          gap={2}>
          <Button
            variant="outlined"
            type="reset"
            onClick={() => form.reset()}
            disabled={!form.formState.isDirty}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={!form.formState.isDirty}>
            Opslaan
          </Button>
        </Stack>
      </PageWrapper>
    </OvFormProvider>
  )
})
