import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getFromStorage } from '@helpers/storage.helpers'

const _api_ = process.env.REACT_APP_API_URL
const _kvk_ = process.env.REACT_APP_KVK_API_URL
const _kvkKey_ = process.env.REACT_APP_KVK_API_KEY

const apiConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  maxContentLength: 10000,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json',
  },
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers']
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.Authorization = `Bearer ${token}`
      return JSON.stringify(data)
    },
  ],
}

const KVKConfig: AxiosRequestConfig = {
  baseURL: 'https://ssltest.kvk.nl',
  headers: {
    apikey: 'l7xx1f2691f2520d487b902f4e0b57a0b197',
    accept: 'application/hal+json',
  },
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers']
    ): AxiosResponse['data'] => {
      console.log('KVK: ', _kvkKey_)
      console.log('headers: ', headers)
      return JSON.stringify(data)
    },
  ],
}

const downloadConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  responseType: 'blob',
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers']
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.authorization = `Bearer ${token}`
      return data
    },
  ],
}

const uploadConfig: AxiosRequestConfig = {
  baseURL: _api_,
  timeout: 1000 * 60,
  maxContentLength: 10000,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    type: 'formData',
  },
  transformRequest: [
    (
      data: AxiosResponse['data'],
      headers: AxiosResponse['headers']
    ): AxiosResponse['data'] => {
      const token = getFromStorage('access_token')
      if (token) headers.authorization = `Bearer ${token}`
      return data
    },
  ],
}

export default {
  api: apiConfig,
  download: downloadConfig,
  upload: uploadConfig,
  kvk: KVKConfig,
}
