import {
  Collapse,
  FormControl,
  FormHelperText,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { useMemo } from 'react'

export interface IOvInput extends TextFieldProps<'outlined'> {
  onChangeCallback?: (value: string | number) => void
  key?: any
  value?: any
  helperText?: string
  // labelKey: string
  // valueKey: string
  size?: SelectProps['size']
  readOnly?: boolean
  // withEmpty?: boolean
  // emptyLabel?: string
  sx?: SelectProps['sx']
}

export const OvInput = ({
  error,
  helperText = '',
  onChangeCallback,
  key,
  label,
  value,
  size,
  readOnly,
  sx,
  ...rest
}: IOvInput) => {
  const handleChange = (newValue: any) => {
    if (onChangeCallback) onChangeCallback(newValue)
  }

  const generateInputId = useMemo(
    () => `input_${label}-component-${Math.random()}`,
    []
  )

  return (
    <FormControl
      variant="outlined"
      error={error}
      size={size}
      sx={{ minWidth: { md: 130 }, width: '100%', ...sx }}>
      <TextField
        sx={sx}
        size={size}
        id={generateInputId}
        error={error}
        key={key}
        disabled={readOnly}
        value={value}
        onChange={e => handleChange(e.target.value)}
        label={label}
        {...rest}
      />
      <Collapse in={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}
