import { DataGridProps } from '@mui/x-data-grid'

export const DATA_GRID: Partial<DataGridProps> = {
  initialState: {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 15,
      },
    },
  },
  checkboxSelection: true,
}
