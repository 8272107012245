import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
  error?: Error
}

export class OvErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error, hasError: true }
  }

  public render(): React.ReactNode {
    const { error, hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Box>
          <Card>
            <CardHeader>Er gaat bij ons iets niet helemaal goed.</CardHeader>
            <CardContent>
              <Typography>
                Er is een interne fout opgetreden. Maak je geen zorgen, je
                gegevens zijn veilig. De fout is:{` ${error}`}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )
    }

    return children
  }
}
