import { BaseApi } from './_base.api'
import { ENDPOINTS, NEW_ENDPOINTS } from '@constants'
import { IUser, IUserLoginCreds } from '@typings'
import { AxiosResponse } from 'axios'
import { IAuthUser } from 'src/typings/auth.typings'

export class AuthApi extends BaseApi {
  getToken = async ({ email, password }: IUserLoginCreds) => {
    return await this.api
      .post(ENDPOINTS.AUTH.TOKEN, {
        email,
        password,
      })
      .then(
        (
          res: AxiosResponse<{
            access_token: string
            refresh_token: string
          }>
        ) => res.data
      )
  }

  login = async ({ email, password }: IUserLoginCreds) =>
    await this.api
      .post<AxiosResponse<IAuthUser>>(NEW_ENDPOINTS.AUTH.LOGIN, {
        email,
        password,
      })
      .then(res => res.data.data)

  signUp = (data: IUser) =>
    this.api.post(ENDPOINTS.AUTH.SIGNUP, { ...data }).then(res => res.data)

  refreshToken = async (refresh_token: string) => {
    return await this.api
      .post(ENDPOINTS.AUTH.REFRESH_TOKEN, { refresh_token })
      .then(res => res.data)
  }

  logout = async (refresh_token: string) =>
    await this.api
      .post(ENDPOINTS.AUTH.LOGOUT, { refresh_token })
      .then(res => res)
}
