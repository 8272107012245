import {
  ICompany,
  ICompanyClient,
  ICompanyClientContact,
  ICompanyInvoice,
  ISingleClient,
  ISingleClientContact,
  ISingleCompany,
  ISingleDocument,
} from '@typings'
import { STORES } from './keys.contants'

const API = '/api'
const LOGIN = '/login'
const KVK = '/kvk'
const AUTH = '/auth'
const DOCUMENTS = '/documents'
const TOKEN = '/token'
const COMPANY = '/company'
const COMPANIES = '/companies'
const CONTACTS = '/contacts'
const CREATE = '/create'
const CREATE_EMPTY = '/create-empty'
const CLIENT = '/client'
const CLIENTS = '/clients'
const TOKEN_REFRESH = '/token-refresh'
const LOGOUT = '/logout'
const LOCATIONS = '/locations'
const SIGNUP = '/signup'
const USER = '/user'
const UPDATE = '/update'
const REMOVE = '/remove'
const WHO_AM_I = '/who-am-i'
const MY_COMPANY = '/my-company'
const VAT = '/vat'
const SHARED = '/shared'
const COUNTRIES = '/countries'

//Partials

const TASKS = '/tasks'
const BANK_ACCOUNT = '/bank-account'
const INVOICES = '/invoices'
const ALL = '/all'

export const NEW_ENDPOINTS = {
  AUTH: {
    LOGIN: `${AUTH}${LOGIN}`,
  },
  DOCUMENTS: {
    LIST: `${DOCUMENTS}`,
    SINGLE: (ID: ISingleDocument['id']) => `${DOCUMENTS}/${ID}`,
  },
  CLIENT: {
    LIST: `${CLIENTS}`,
    SINGLE: (ID: ISingleClient['id']) => `${CLIENTS}/${ID}`,
    CONTACTS: {
      LIST: `${CLIENT}${CONTACTS}`,
      SINGLE: (ID: ISingleClientContact['id']) => `${CLIENT}${CONTACTS}/${ID}`,
    },
  },
  COMPANIES: {
    LIST: `${COMPANIES}`,
    SINGLE: (ID: ISingleCompany['id']) => `${COMPANIES}/${ID}`,
  },
  SHARED: {
    VAT: `${SHARED}${VAT}`,
    COUNTRIES: `${SHARED}${COUNTRIES}`,
  },
}

export const ENDPOINTS = {
  AUTH: {
    TOKEN: `${AUTH}${TOKEN}`,
    REFRESH_TOKEN: `${AUTH}${TOKEN_REFRESH}`,
    LOGOUT: `${AUTH}${LOGOUT}`,
    SIGNUP: `${AUTH}${SIGNUP}`,
  },
  COMPANY: {
    GET_MY_COMPANY: `${COMPANY}${MY_COMPANY}`,
    CREATE: `${COMPANY}${CREATE}`,
    TASKS: {
      ALL: `${COMPANY}${TASKS}${ALL}`,
      CREATE: `${COMPANY}${TASKS}${CREATE}`,
    },
    BANK_ACCOUNT: {
      CREATE: `${COMPANY}${BANK_ACCOUNT}${CREATE}`,
      ALL: `${COMPANY}${BANK_ACCOUNT}${ALL}`,
    },
    INVOICES: {
      SINGLE: (id: ICompanyInvoice['id']) => `${COMPANY}${INVOICES}/${id}`,
      CREATE: `${COMPANY}${INVOICES}${CREATE}`,
      ADD_TASK: (id: ICompanyInvoice['id']) =>
        `${COMPANY}${INVOICES}/${id}/task/add`,
      CREATE_EMPTY: `${COMPANY}${INVOICES}${CREATE_EMPTY}`,
      ALL: `${COMPANY}${INVOICES}${ALL}`,
      REMOVE: `${COMPANY}${INVOICES}${REMOVE}`,
    },
    CLIENTS: {
      CREATE: `${COMPANY}${CLIENTS}${CREATE}`,
      ALL: `${COMPANY}${CLIENTS}${ALL}`,
      CONTACTS: {
        ALL: (ID: ICompanyClient['id']) =>
          `${COMPANY}${CLIENTS}/${ID}${CONTACTS}${ALL}`,
        CREATE: (ID: ICompanyClient['id']) =>
          `${COMPANY}${CLIENTS}/${ID}${CONTACTS}${CREATE}`,
        SINGLE: (
          CLIENT_ID: ICompanyClient['id'],
          CONTACT_ID: ICompanyClientContact['id']
        ) => `${COMPANY}${CLIENTS}/${CLIENT_ID}${CONTACTS}/${CONTACT_ID}`,
      },
    },
    LOCATIONS: {
      CREATE: `${COMPANY}${LOCATIONS}${CREATE}`,
      ALL: `${COMPANY}${LOCATIONS}${ALL}`,
    },
  },
  USER: {
    WHO_AM_I: `${USER}${WHO_AM_I}`,
    UPDATE: `${USER}${UPDATE}`,
  },
  KVK: {
    ALL: `${KVK}${ALL}`,
  },
  VAT: {
    ALL: `${VAT}${ALL}`,
  },
}

export const DATAGRID_ENDPOINTS = {
  [STORES.INVOICE]: {
    GET: NEW_ENDPOINTS.DOCUMENTS.LIST,
    REMOVE: ENDPOINTS.COMPANY.INVOICES.REMOVE,
  },
  [STORES.QUOTATION]: {
    GET: NEW_ENDPOINTS.DOCUMENTS.LIST,
  },
  [STORES.CLIENT]: {
    GET: NEW_ENDPOINTS.CLIENT.LIST,
  },
}
