export const INVOICE_DEFAULT_VALUES = {
  client_id: '',
  type: 'invoice',
  number: '',
  reference: '',
  expires_at: '',
  subtotal: 0,
  issued_at: new Date(),
  vat: 0,
  total: 0,
  created_at: '',
  updated_at: '',
  lines: [],
}
