import {
  OvCard,
  OvForm,
  OvFormContext,
  OvFormInput,
  OvFormInputReadOnly,
} from '@components'
import { LABELS } from '@constants'
import { Stack } from '@mui/material'
import { ISingleCompany } from '@typings'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

export const MyCompanyFormGeneral = observer(() => {
  const FormInput = OvFormInput<ISingleCompany>
  const { readOnly } = useContext(OvFormContext)
  const {
    formState: { defaultValues },
  } = useFormContext()
  return (
    <OvForm>
      <OvCard>
        <Stack
          direction="column"
          gap={2}>
          {readOnly ? (
            <OvFormInputReadOnly
              label={LABELS.NAME}
              value={defaultValues?.name}
            />
          ) : (
            <FormInput
              label={LABELS.NAME}
              name="name"
            />
          )}
          <FormInput
            label={LABELS.COC}
            name="coc"
          />
          <FormInput
            label={LABELS.TAX_NUMBER}
            name="tax"
          />
        </Stack>
      </OvCard>
    </OvForm>
  )
})
