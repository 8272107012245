import { BaseApi } from './_base.api'

export class DataGridApi extends BaseApi {
  getAll = (
    endpoint: string,
    params: any
  ): Promise<{ data: any[]; meta: any; links: any }> =>
    this.api
      .get(endpoint, {
        params: { ...params, per_page: 99999 },
      })
      .then(response => response.data)

  handleRemoveSelection = (endpoint: string, invoice: number[]) =>
    this.api.post(endpoint, { invoice })
}
