import { PageWrapper } from '@components'
import { INVOICE_DEFAULT_VALUES } from '@constants'
import { useStore } from '@hooks/useStore'
import { Check, Download, Edit, Save, SwapHoriz } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { ISingleDocument } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'

import { UpdateInvoiceForm } from 'src/partials/index.partials'

export const InvoiceDetail = observer(() => {
  const { ui, invoice, shared, client } = useStore()
  const [finalized, setFinalized] = useState(false)
  const [edit, setEdit] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()

  const _id = id && parseInt(id)

  if (!_id) return null

  const getDefaultValues = async () => {
    return await invoice.getSingle(_id)
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleBack = () => navigate('..')

  const handleSuccess = (newDocument: ISingleDocument) => {
    setEdit(false)
    form.reset(newDocument)
    ui.handleToaster('Wijzigingen opgeslagen', { type: 'success' })
  }

  const onSubmitCallback = async (values: FieldValues) => {
    try {
      const res = await invoice.update(values)
      handleSuccess(res)
    } catch (e: any) {
      handleErrors(e.errors, e.message)
    }
  }

  const handleErrors = (errors: Record<string, string[]>, message?: string) => {
    console.log('errors: ', errors)
    form.clearErrors()
    const errorKeys = Object.keys(errors)

    errorKeys.map(singleKey =>
      form.setError(singleKey as keyof typeof INVOICE_DEFAULT_VALUES, {
        type: 'custom',
        message: errors[singleKey][0],
      })
    )

    if (message) toast(message, { type: 'error' })
  }

  const handleFinalize = async () => {
    await ui
      .confirm({
        title: 'Factuur definitief maken?',
        content:
          // eslint-disable-next-line max-len
          'Door akkoord te gaan maak je de factuur definitief. Je kan deze hierna niet meer wijzigen. Indien je een definitieve factuur ongedaan wil maken, dien je een credit nota te maken. Weet je zeker dat je de factuur definitief wilt maken?',
        withCancel: true,
      })
      .then(() => setFinalized(true))
      .catch(e => void 0)
  }

  const handleSubmit = () => form.handleSubmit(onSubmitCallback)()

  const handleCancel = () => {
    form.reset()
    setEdit(false)
  }

  useEffect(() => {
    if (!shared.has_vat_codes) shared.getVatCodes()
    if (edit) {
      if (!client.has_clients) client.getAll()
      if (!shared.has_countries) shared.getCountries()
    }
  }, [edit, shared.has_countries, shared.has_vat_codes, shared.has_clients])

  return (
    <OvFormProvider
      loading={invoice.loading}
      form={form}
      readOnly={!edit}
      onSubmit={handleSubmit}>
      <PageWrapper
        backAction={handleBack}
        pageTitle="Factuur"
        actions={
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
            width={'100%'}
            gap={1}>
            {edit && (
              <Button
                variant="outlined"
                onClick={handleCancel}>
                Annuleren
              </Button>
            )}
            {!finalized && (
              <Button
                endIcon={edit ? <Save /> : <Edit />}
                variant={edit ? 'contained' : 'outlined'}
                onClick={edit ? handleSubmit : () => setEdit(true)}>
                {edit ? 'Factuur Opslaan' : 'Factuur bewerken'}
              </Button>
            )}
            {!edit && !finalized && (
              <Button
                variant="contained"
                endIcon={<Check />}
                onClick={handleFinalize}>
                Maak definitief
              </Button>
            )}
            {finalized && (
              <>
                <Button
                  variant="outlined"
                  endIcon={<SwapHoriz />}>
                  Crediteer
                </Button>
                <Button
                  variant="contained"
                  endIcon={<Download />}>
                  Download
                </Button>
              </>
            )}
          </Box>
        }>
        <UpdateInvoiceForm />
      </PageWrapper>
    </OvFormProvider>
  )
})
