import { DYNAMIC_LABELS, LABELS, URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface IOVProfilebutton {
  variant?: 'narrow' | 'full'
}

export const OVProfileButton = observer(
  ({ variant = 'full' }: IOVProfilebutton) => {
    const { auth, user } = useStore()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const navigate = useNavigate()

    const handleUserMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
      anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
    }

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          gap={1}>
          <Avatar
            onClick={e => handleUserMenuClick(e)}
            variant="rounded"
            sx={theme => ({
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
            })}>
            {user.current_user_initial}
          </Avatar>
          <Collapse
            orientation="horizontal"
            in={variant === 'full'}>
            <Typography>{user.current_user_name}</Typography>
          </Collapse>
        </Box>
        <Popover
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <Paper sx={{ minWidth: '320px' }}>
            <MenuList>
              <MenuItem onClick={() => navigate(URL_PATHS.PROFILE)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText>{LABELS.MY_PROFILE}</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => auth.logout()}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>{LABELS.LOGOUT}</ListItemText>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      </>
    )
  }
)
