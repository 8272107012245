import { AuthApi } from './auth.api'
import { ClientApi } from './client.api'
import { CompanyApi } from './company.api'
import { DataGridApi } from './datagrid.api'
import { DocumentsApi } from './documents.api'
import { KvkApi } from './kvk.api'
import { UserApi } from './user.api'
import { SharedApi } from './shared.api'
export { BaseApi } from './_base.api'

export interface IApi {
  auth: AuthApi
  user: UserApi
  client: ClientApi
  datagrid: DataGridApi
  kvk: KvkApi
  company: CompanyApi
  shared: SharedApi
  documents: DocumentsApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const datagrid = new DataGridApi()
  const client = new ClientApi()
  const user = new UserApi()
  const kvk = new KvkApi()
  const documents = new DocumentsApi()
  const company = new CompanyApi()
  const shared = new SharedApi()

  return { auth, user, kvk, company, client, datagrid, shared, documents }
}
