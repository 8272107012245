export const QUOTATION_DEFAULT_VALUES = {
  client_id: '',
  type: 'quotation',
  number: '',
  reference: '',
  expires_at: '',
  subtotal: 0,
  vat: 0,
  total: 0,
  created_at: '',
  updated_at: '',
  lines: [],
}
