// export const formatCentToAmount = (value: number) => {
//   const _value = value / 100
//   return Number((Math.round((_value + Number.EPSILON) * 100) / 100).toFixed(2))
// }

export const currencyFormatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const roundNumber = (number: number) =>
  Number((Math.round((number + Number.EPSILON) * 100) / 100).toFixed(2))

export const formatValueToCurrency = (value: number) => {
  const _value = roundNumber(value)
  return currencyFormatter.format(_value / 100)
}
