import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'

export const useIsMobile = () => {
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.between('xs', 'md')
  )

  const _isMobile = useMemo(() => isMobile, [isMobile])

  return _isMobile
}
