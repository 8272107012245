import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { ISingleCountry, IVatCode } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { Store } from './index.stores'

export class SharedStore {
  [key: string]: any
  store: Store
  loading: boolean
  vat_codes: IVatCode[]
  countries: ISingleCountry[]
  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.vat_codes = []
    this.countries = [] as ISingleCountry[]
  }

  @computed
  get has_vat_codes(): boolean {
    return !!this.vat_codes.length
  }

  @computed
  get has_countries(): boolean {
    return !!this.countries.length
  }

  @computed
  get current_vat_codes(): IVatCode[] {
    return this.vat_codes
  }

  @action
  getVatCodes = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const res = await this.store.api.shared.getVatCodes()
      this.set(STORE_KEYS.VAT_CODES, res, true)
    } catch (e) {
      console.log('e', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  getCountries = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const res = await this.store.api.shared.getCountries()
      this.set(STORE_KEYS.COUNTRIES, res, true)
    } catch (e) {
      console.log('e', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
