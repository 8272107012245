import { DATAGRID_ENDPOINTS, STORE_KEYS } from '@constants'
import { createDataGridResponse } from '@helpers/datagrid.helper'
import { saveToStorage } from '@helpers/storage.helpers'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { Store } from '@stores'
import {
  IDatagridColumns,
  IDatagridResponsiveColumn,
  IDatagridRow,
  IDatagridRows,
} from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { DATA_GRID_COLUMNS } from 'src/constants/datagrid/datagrid.constants'

const initialValues: Record<string, any> = {
  rows: [],
  columns: [],
  query: '',
  loading: true,
  selected: [],
  pagination: {},
}

export class DatagridStore {
  [key: string]: any
  store: Store
  rows: IDatagridRows
  columns: IDatagridColumns
  query: string
  storeKey: string
  loading: boolean
  selected: IDatagridRow['id'][]
  pagination: any

  constructor(
    globalStore: Store,
    storeKey: string,
    defaultParams?: Record<string, any>
  ) {
    makeAutoObservable(this)
    this.store = globalStore
    this.storeKey = storeKey
    this.rows = initialValues.rows
    this.columns = initialValues.columns
    this.query = initialValues.query
    this.loading = initialValues.loading
    this.selected = initialValues.selected
    this.defaultParams = defaultParams
    this.pagination = initialValues.pagination
  }

  @computed
  get is_loading(): boolean {
    return this.loading
  }

  @computed
  get has_selected_values(): boolean {
    return this.selected.length > 0
  }

  @computed
  get current_rows(): IDatagridRows {
    return this.rows ?? []
  }

  @computed
  get current_pagination(): any {
    return this.pagination
  }

  @action
  getData = async (
    params?: Record<string, any>,
    useMobileColumns?: boolean,
    customColumns?: IDatagridResponsiveColumn
  ) => {
    this.set(STORE_KEYS.LOADING, true)
    const columnKey = useMobileColumns ? 'mobile' : 'default'

    const _params = { ...this.defaultParams, ...params }
    try {
      const res = await this.store.api.datagrid.getAll(
        DATAGRID_ENDPOINTS[this.storeKey].GET,
        _params
      )
      const _columns = customColumns ?? DATA_GRID_COLUMNS[this.storeKey]
      const { rows, columns } = createDataGridResponse(
        _columns[columnKey],
        res.data
      )

      this.set('pagination', res.meta)
      this.set(STORE_KEYS.ROWS, rows)
      this.set(STORE_KEYS.COLUMNS, columns)
    } catch (e) {
      // this.store.ui.handleErrorToast()
      console.error(e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  removeSelection = async () => {
    try {
      await this.store.ui.confirm({
        title: 'Are you sure?',
        content: 'Removing these items is permanent and can not be undone',
        confirmLabel: 'Continue',
        withCancel: true,
      })
      await this.store.api.datagrid.handleRemoveSelection(
        DATAGRID_ENDPOINTS[this.storeKey].REMOVE as string,
        this.selected
      )

      this.getData()
    } catch (e) {
      void 0
    }
  }

  @action
  handleSelection = (selection: GridRowSelectionModel) => {
    this.set(STORE_KEYS.SELECTED, selection)
  }

  @action
  reset = () => {
    for (const key in initialValues) {
      this.set(key, initialValues[key])
    }
  }

  @action
  set = (key: string, value: unknown, save?: boolean): void => {
    if (!key) return
    this[key] = value
    if (save) saveToStorage(key as string, value)
  }
}
