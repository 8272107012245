import { createContext } from 'react'
import Api, { IApi } from '@api'
import { makeAutoObservable } from 'mobx'
import { UserStore } from './user.stores'
import { UiStore } from './ui.stores'
import { AuthStore } from './auth.stores'
import { CompanyStore } from './company.stores'
import { DashboardStore } from './dashboard.stores'
import { InvoiceStore } from './invoice.stores'
import { ClientStore } from './client.stores'
import { KvKStore } from './kvk.stores'
import { SharedStore } from './shared.stores'
import { QuotationStore } from './quotation.stores'

export class Store {
  [key: string]: any
  api: IApi
  auth: AuthStore
  user: UserStore
  ui: UiStore
  dashboard: DashboardStore
  kvk: KvKStore
  company: CompanyStore
  invoice: InvoiceStore
  quotation: QuotationStore
  client: ClientStore
  shared: SharedStore

  constructor() {
    makeAutoObservable(this)
    this.api = Api()
    this.auth = new AuthStore(this)
    this.dashboard = new DashboardStore(this)
    this.user = new UserStore(this)
    this.company = new CompanyStore(this)
    this.kvk = new KvKStore(this)
    this.quotation = new QuotationStore(this)
    this.ui = new UiStore(this)
    this.shared = new SharedStore(this)
    this.client = new ClientStore(this)
    this.invoice = new InvoiceStore(this)
  }
}

export const StoreContext = createContext<Store>(new Store())
