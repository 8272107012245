import { NEW_ENDPOINTS } from '@constants'
import { ISingleClient, ISingleClientContact, ISingleDocument } from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { BaseApi } from './_base.api'

export class ClientApi extends BaseApi {
  getAll = () =>
    this.api
      .get<AxiosResponse<ISingleClient[]>>(NEW_ENDPOINTS.CLIENT.LIST)
      .then(res => res.data.data)

  create = (data: Partial<ISingleClient>) => {
    return this.api
      .post(NEW_ENDPOINTS.CLIENT.LIST, data)
      .then(res => res.data.data)
  }

  getContacts = (client_id: ISingleClient['id']) =>
    this.api
      .get(NEW_ENDPOINTS.CLIENT.CONTACTS.LIST, { params: { client_id } })
      .then(res => res.data.data)

  getContact = (contactId: ISingleClientContact['id']) =>
    this.api
      .get(NEW_ENDPOINTS.CLIENT.CONTACTS.SINGLE(contactId))
      .then(res => res.data.data)

  updateContact = (data: ISingleClientContact) =>
    this.api
      .put(NEW_ENDPOINTS.CLIENT.CONTACTS.SINGLE(data.id), data)
      .then(res => res.data.data)

  addContact = (data: ISingleClientContact) =>
    this.api
      .post(NEW_ENDPOINTS.CLIENT.CONTACTS.LIST, data)
      .then(res => res.data.data)

  removeContact = (id: ISingleClientContact['id']) =>
    this.api
      .delete(NEW_ENDPOINTS.CLIENT.CONTACTS.SINGLE(id))
      .then(res => res.data.data)

  update = (id: ISingleClient['id'], data: ISingleClient) =>
    this.api
      .put(NEW_ENDPOINTS.CLIENT.SINGLE(id), data)
      .then(res => res.data.data)

  getSingle = (
    id: ISingleClient['id'],
    params?: AxiosRequestConfig['params']
  ) =>
    this.api
      .get<AxiosResponse<ISingleDocument>>(
        NEW_ENDPOINTS.CLIENT.SINGLE(id),
        params
      )
      .then(res => res.data.data)
}
