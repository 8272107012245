/* eslint-disable max-len */
import { experimental_extendTheme as extendTheme, responsiveFontSizes } from '@mui/material/styles'

const customTheme = extendTheme({
  cssVarPrefix: 'outvoice',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#311b92',
        },
        secondary: {
          main: '#f50057',
        },
        error: {
          main: '#d50000',
        },
        success: {
          main: '#00c853',
        },
        info: {
          main: '#0091ea',
        },
        background: {
          default: '#f5f5f5',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: '#7e57c2',
        },
        secondary: {
          main: '#f50057',
        },
        error: {
          main: '#d50000',
        },
        success: {
          main: '#00c853',
        },
        info: {
          main: '#0091ea',
        },
      },
    },
  },
  typography: {
    fontFamily: 'PT Sans',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    h1: {
      fontFamily: 'Roboto Slab',
      fontWeight: 500,
      fontSize: '3rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: 'Roboto Slab',
      fontSize: '2.4rem',
      fontWeight: 500,
      color: '#311b92',
    },
    h3: {
      fontFamily: 'Roboto Slab',
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: 'Roboto Slab',
      fontSize: '1.2rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: 'Roboto Slab',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: 'Roboto Slab',
      fontSize: '0.8rem',
      lineHeight: 1.2,
    },
    button: {
      fontSize: '1.1rem',
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition:
            'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
        spacing: 2,
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: ({ theme: { palette } }) => ({
          backgroundColor: palette.primary.mainChannel,
        }),
      },
    },
    // MuiDataGrid: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: 'bold',
    //     },
    //   },
    // },
  },
})

export const responsiveTheme = responsiveFontSizes(customTheme)