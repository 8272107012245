import { URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import { GridRowParams } from '@mui/x-data-grid'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { OvDataGrid } from 'src/components/core/ov-data-grid/ov-data-grid'
import { DATA_GRID_CUSTOM_COLUMNS } from 'src/constants/datagrid/datagrid.constants'

export const OvClientQuotationsTab = observer(() => {
  const { quotation } = useStore()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleRowClick = (params: GridRowParams) => {
    if (!params.id) return
    const path = generatePath(URL_PATHS.QUOTE_DETAIL, { id: params.id })
    return navigate(path)
  }

  const getDefaultParams = useMemo(() => {
    const params: Record<string, any> = {}
    if (id) {
      const _id = parseInt(id)
      params['client_id'] = _id
    }
    return params
  }, [id])

  return (
    <OvDataGrid
      onRowClick={handleRowClick}
      store={quotation.data_grid}
      customColumns={DATA_GRID_CUSTOM_COLUMNS.CLIENT_QUOTES}
      responsiveColumns
      params={getDefaultParams}
    />
  )
})
