import { LABELS, URL_PATHS } from '@constants'
import { getFromStorage, removeFromStorage } from '@helpers/storage.helpers'
import { useStore } from '@hooks/useStore'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormHelperText,
} from '@mui/material'
import { IOVErrorResponseData, IUserLoginCreds } from '@typings'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { redirect, useNavigate } from 'react-router-dom'
import { OvFormInput } from 'src/components/core/ov-form-input/ov-form-input'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'
import { OvForm } from 'src/components/core/ov-form/ov-form'
import styles from './login.form.module.scss'

export const LoginForm = observer(() => {
  const { auth } = useStore()
  const [formError, setFormError] = useState<string | null>(null)
  const navigate = useNavigate()

  const form = useForm<IUserLoginCreds>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  })

  const { formState } = form
  const intended = getFromStorage('intended')

  const handleLogin: SubmitHandler<IUserLoginCreds> = async data => {
    setFormError(null)
    await auth
      .login(data)
      .then(() => {
        if (intended) removeFromStorage('intended')
        return redirect(intended ? intended : URL_PATHS.DASHBOARD)
      })
      .catch((errors: IOVErrorResponseData) => handleFormErrors(errors))
  }

  const handleFormErrors = (data: IOVErrorResponseData) => {
    const { message, errors } = data

    if (message) setFormError(message)

    // if (errors) {
    //   const errorKeys = Object.keys(errors)

    //   errorKeys.map(singleKey =>
    //     form.setError(singleKey as any, {
    //       type: 'custom',
    //       message: errors[singleKey][0],
    //     })
    //   )
    // }
  }

  const sumitForm = () => {
    form.handleSubmit(handleLogin)()
  }

  return (
    <OvFormProvider
      form={form}
      onSubmit={sumitForm}
      loading={auth.loading}>
      <OvForm>
        <Card
          className={styles['login-card']}
          elevation={4}>
          <CardHeader title={LABELS.LOGIN} />
          <CardContent className={styles['login-card-content']}>
            <OvFormInput
              name="email"
              inputProps={{ type: 'email' }}
              label={LABELS.EMAIL}
              rules={{ required: 'E-mail is verplicht' }}
              required
            />
            <OvFormInput
              name="password"
              inputProps={{ type: 'password' }}
              label={LABELS.PASSWORD}
              rules={{ required: 'Wachtwoord is verplicht' }}
              required
            />
            <Collapse in={!!formError}>
              <FormHelperText error>{formError}</FormHelperText>
            </Collapse>
          </CardContent>
          <CardActions>
            <Button
              variant="text"
              color="secondary"
              disabled={formState.isSubmitting}
              size="small">
              {LABELS.PASSWORD_FORGOT}
            </Button>
            <Button
              disabled={
                formState.isSubmitting || auth.loading || !formState.isValid
              }
              variant="contained"
              type="submit"
              sx={{ flex: '1 0 auto' }}>
              {LABELS.LOGIN}
            </Button>
          </CardActions>
        </Card>
      </OvForm>
    </OvFormProvider>
  )
})
