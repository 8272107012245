import {
  MyCompanyFormGeneral,
  MyCompanyFormInvoice,
} from 'src/partials/index.partials'

export const MY_COMPANY_TABS = [
  {
    label: 'Bedrijfsgegevens',
    value: '1',
    component: <MyCompanyFormGeneral />,
  },
  {
    label: 'Factuur instellingen',
    value: '2',
    component: <MyCompanyFormInvoice />,
  },
  {
    label: 'Gebruikers',
    value: '3',
    disabled: true,
  },
]
