export const CLIENT_DEFAULT_VALUES = {
  country_id: '',
  name: '',
  street: '',
  postal_code: '',
  city: '',
  coc: '',
  tax: '',
  hourly_rate: null,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  contacts: []
}
