import { IDatagridColumn, IFormatDatagridColumn } from '@typings'

export const formatDatagridColumns = (
  columns: IFormatDatagridColumn[]
): IDatagridColumn[] =>
  columns.map(singleColumn => {
    const obj = {
      field: Array.isArray(singleColumn.key)
        ? (singleColumn.columnKey as string)
        : singleColumn.key,
      headerName: singleColumn?.label,
      flex: singleColumn?.flex ?? 1,
      type: singleColumn?.type ?? 'string',
    }
    return obj
  })

export const formatDatagridRows = (
  columns: IFormatDatagridColumn[],
  data: any[]
) => {
  const rows = data.map(singleRow => {
    const defaultValue = { id: singleRow.id }
    const formattedRow = columns.reduce((bulk, curr) => {
      if (Array.isArray(curr.key)) {
        const arrayLength = curr.key.length
        const data = singleRow?.[curr.key[0]]?.[curr.key[1]] ?? null
        const _data = arrayLength === 3 ? data?.[curr.key[2]] : data

        return { ...bulk, [curr.columnKey as string]: _data }
      } else {
        return { ...bulk, [curr.key]: singleRow[curr.key] }
      }
    }, defaultValue)
    return formattedRow
  })

  return rows
}

export const createDataGridPagination = (data: any) => {
  const pagination = {
    page: data.current_page - 1,
    pageSize: data.total,
  }

  return pagination
}

export const createDataGridResponse = (
  columns: IFormatDatagridColumn[],
  data: any[]
) => {
  const _rows = formatDatagridRows(columns, data)
  const _columns = formatDatagridColumns(columns)

  return { columns: _columns, rows: _rows }
}
