import { createContext, useState } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'
import { OvFormNavigationBlocker } from '../index.core.component'

export type IOvFormProvider<T extends FieldValues> = {
  children: React.ReactNode
  readOnly?: boolean
  loading?: boolean
  isCreate?: boolean
  onSubmit: SubmitHandler<Record<string, unknown>>
  form: UseFormReturn<T>
}

export type IOvFormProviderMethods<T extends FieldValues> = UseFormReturn<T>

type IOvFormContext = {
  registerInput: (inputConfig: {
    name: string
    validate?: string
    label?: string
  }) => void
  onFormSubmit: SubmitHandler<Record<string, unknown>>
} & Pick<IOvFormProvider<FieldValues>, 'readOnly' | 'loading'>

export const OvFormContext = createContext<IOvFormContext>({
  readOnly: false,
  loading: false,
  registerInput: () => void 0,
  onFormSubmit: () => void 0,
})

const OvFormProvider = <T extends FieldValues>({
  children,
  readOnly,
  loading,
  onSubmit,
  form,
}: // form,
IOvFormProvider<T>) => {
  const [rules, setRules] = useState(new Map())
  const [fieldLabels, setFieldLabels] = useState<Map<keyof T, string>>(
    new Map()
  )

  const registerInput = ({
    name,
    validate,
    label,
  }: Parameters<IOvFormContext['registerInput']>[0]) => {
    setRules(rules => new Map(rules.set(name, validate)))
    if (label) setFieldLabels(labels => new Map(labels.set(name, label)))
  }

  return (
    <FormProvider {...form}>
      <OvFormContext.Provider
        value={{
          registerInput,
          readOnly,
          loading,
          onFormSubmit: onSubmit,
        }}>
        {/* <OvFormNavigationBlocker> */}
        {children}
        {/* </OvFormNavigationBlocker> */}
      </OvFormContext.Provider>
    </FormProvider>
  )
}

OvFormProvider.displayName = 'OvFormProvider'
export { OvFormProvider }
