import { ENDPOINTS, NEW_ENDPOINTS } from '@constants'
import { ISingleDocument } from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { BaseApi } from './_base.api'

export class DocumentsApi extends BaseApi {
  getAll = (params?: AxiosRequestConfig['params']) =>
    this.api.get(NEW_ENDPOINTS.DOCUMENTS.LIST, params).then(res => res.data)

  getSingle = (
    id: ISingleDocument['id'],
    params?: AxiosRequestConfig['params']
  ) =>
    this.api
      .get<AxiosResponse<ISingleDocument>>(
        NEW_ENDPOINTS.DOCUMENTS.SINGLE(id),
        params
      )
      .then(res => res.data.data)

  create = (data: Partial<ISingleDocument>) =>
    this.api.post(NEW_ENDPOINTS.DOCUMENTS.LIST, data).then(res => res.data.data)

  update = (id: ISingleDocument['id'], data: ISingleDocument) =>
    this.api
      .put(NEW_ENDPOINTS.DOCUMENTS.SINGLE(id), data)
      .then(res => res.data.data)
}
