import { Typography } from '@mui/material'
import { forwardRef } from 'react'

export interface IOvFormInputReadOnly {
  label: string
  value?: any
  children?: React.ReactNode
}

const OvFormInputReadOnly = forwardRef<HTMLDivElement, IOvFormInputReadOnly>(
  ({ label, value, children }, ref) => (
    <div ref={ref}>
      {label && (
        <Typography
          variant="h5"
          sx={({ palette }) => ({ color: palette.primary.main, mb: 1 })}>
          {label}
        </Typography>
      )}
      {value && <Typography variant="body2">{value}</Typography>}
      {children}
    </div>
  )
)

OvFormInputReadOnly.displayName = 'OvFormInput'

export { OvFormInputReadOnly }
