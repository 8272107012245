import { PageWrapper } from '@components'
import { LABELS, URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { GridRowParams } from '@mui/x-data-grid'
import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate } from 'react-router-dom'
import { OvDataGrid } from 'src/components/core/ov-data-grid/ov-data-grid'

export const QuotationListView = observer(() => {
  const { quotation } = useStore()
  const navigate = useNavigate()

  const handleRowClick = (params: GridRowParams) => {
    if (!params.id) return
    const path = generatePath(URL_PATHS.QUOTE_DETAIL, { id: params.id })
    return navigate(path)
  }

  const handleNewClick = async () => {
    navigate(URL_PATHS.QUOTE_CREATE)
  }

  return (
    <PageWrapper
      pageTitle={LABELS.QUOTES}
      actions={
        <Button
          variant="contained"
          endIcon={<Add />}
          onClick={handleNewClick}>
          Nieuw
        </Button>
      }>
      <OvDataGrid
        onRowClick={handleRowClick}
        store={quotation.data_grid}
        responsiveColumns
      />
    </PageWrapper>
  )
})
