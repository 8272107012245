import { OvErrorBoundary } from '@components'
import { Outlet } from 'react-router-dom'

export const UnauthenticatedLayout = () => {
  return (
    <main>
      <OvErrorBoundary>
        <Outlet />
      </OvErrorBoundary>
    </main>
  )
}
