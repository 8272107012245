import {
  ClientCreateView,
  ClientDetailView,
  ClientsList,
  DashboardView,
  InvoiceCreateView,
  InvoiceList,
  LoginView,
  MyCompanyView,
  ProfileView,
  QuotationDetailView,
  QuotationListView,
} from '@views'
import { Navigate } from 'react-router-dom'
import {
  DefaultWithHeader,
  UnauthenticatedLayout,
} from 'src/layouts/index.layouts'
import { InvoiceDetail } from 'src/views/invoiceDetail/invoiceDetail.view'
import { QuotationCreateView } from 'src/views/quotationCreate/quotationCreate.view'
import { KEYS } from './keys.contants'

export const ROUTE_PARTS = {
  ROOT: '/',
  INVOICE_CREATE: 'maak-factuur',
  INVOICE_LIST: 'facturen',
  INVOICE_SINGLE: ':id',
  CLIENT_LIST: 'klanten',
  CLIENT_DETAIL: ':id',
  CLIENT_CREATE: 'nieuwe-klant',
  LOGIN: 'login',
  ACCOUNT: 'account',
  PROFILE: 'profile',
  QUOTE_LIST: 'offertes',
  QUOTE_DETAIL: ':id',
  QUOTE_CREATE: 'maak-offerte',
  DASHBOARD: 'dashboard',
  SIGNUP: 'aanmelden',
  MY_COMPANY: 'mijn-onderneming',
}

export const URL_PATHS = {
  ACCOUNT: `/${ROUTE_PARTS.ACCOUNT}`,
  CLIENT_LIST: `/${ROUTE_PARTS.CLIENT_LIST}`,
  CLIENT_CREATE: `/${ROUTE_PARTS.CLIENT_LIST}/${ROUTE_PARTS.CLIENT_CREATE}`,
  CLIENT_DETAIL: `/${ROUTE_PARTS.CLIENT_LIST}/${ROUTE_PARTS.CLIENT_DETAIL}`,
  DASHBOARD: `/${ROUTE_PARTS.DASHBOARD}`,
  INVOICE_LIST: `/${ROUTE_PARTS.INVOICE_LIST}`,
  INVOICE_CREATE: `/${ROUTE_PARTS.INVOICE_LIST}/${ROUTE_PARTS.INVOICE_CREATE}`,
  INVOICE_DETAIL: `/${ROUTE_PARTS.INVOICE_LIST}/${ROUTE_PARTS.INVOICE_SINGLE}`,
  LOGIN: `/${ROUTE_PARTS.LOGIN}`,
  MY_COMPANY: `/${ROUTE_PARTS.MY_COMPANY}`,
  PROFILE: `/${ROUTE_PARTS.PROFILE}`,
  QUOTE_LIST: `/${ROUTE_PARTS.QUOTE_LIST}`,
  QUOTE_CREATE: `/${ROUTE_PARTS.QUOTE_LIST}/${ROUTE_PARTS.QUOTE_CREATE}`,
  QUOTE_DETAIL: `/${ROUTE_PARTS.QUOTE_LIST}/${ROUTE_PARTS.QUOTE_DETAIL}`,
  ROOT: '/',
}

export const ROUTES = [
  //Public
  // {
  //   path: URL_PATHS.LOGIN,
  //   id: KEYS.SIGNUP,
  //   layout: <UnauthenticatedLayout />,
  //   element: <SignupView />,
  // },
  {
    id: KEYS.LOGIN,
    path: URL_PATHS.LOGIN,
    element: <LoginView />,
    layout: <UnauthenticatedLayout />,
  },
  {
    path: ROUTE_PARTS.ROOT,
    element: <DefaultWithHeader />,
    children: [
      {
        id: KEYS.QUOTE_LIST,
        path: ROUTE_PARTS.QUOTE_LIST,
        children: [
          {
            element: <QuotationListView />,
            index: true,
          },
          {
            id: KEYS.QUOTE_SINGLE,
            path: ROUTE_PARTS.QUOTE_DETAIL,
            element: <QuotationDetailView />,
            allowed: [],
          },
          {
            id: KEYS.QUOTE_CREATE,
            path: ROUTE_PARTS.QUOTE_CREATE,
            element: <QuotationCreateView />,
            allowed: [],
          },
        ],
        allowed: [],
      },
      {
        id: KEYS.INVOICE_LIST,
        path: ROUTE_PARTS.INVOICE_LIST,
        children: [
          {
            index: true,
            element: <InvoiceList />,
          },
          {
            id: KEYS.INVOICE_SINGLE,
            path: ROUTE_PARTS.INVOICE_SINGLE,
            element: <InvoiceDetail />,
            allowed: [],
          },
          {
            id: KEYS.INVOICE_CREATE,
            path: ROUTE_PARTS.INVOICE_CREATE,
            element: <InvoiceCreateView />,
            allowed: [],
          },
        ],
        allowed: [],
      },
      {
        id: KEYS.CLIENT_LIST,
        path: ROUTE_PARTS.CLIENT_LIST,
        allowed: [],
        children: [
          {
            element: <ClientsList />,
            index: true,
          },
          {
            id: KEYS.CLIENT_DETAIL,
            path: ROUTE_PARTS.CLIENT_DETAIL,
            element: <ClientDetailView />,
            allowed: [],
          },
          {
            id: KEYS.CLIENT_CREATE,
            path: ROUTE_PARTS.CLIENT_CREATE,
            element: <ClientCreateView />,
            allowed: [],
          },
        ],
      },
      {
        path: ROUTE_PARTS.DASHBOARD,
        id: KEYS.DASHBOARD,
        index: true,
        element: <DashboardView />,
        allowed: [],
      },
      {
        path: ROUTE_PARTS.ACCOUNT,
        id: KEYS.PROFILE,
        element: <ProfileView />,
        allowed: [],
      },
      {
        path: ROUTE_PARTS.MY_COMPANY,
        id: KEYS.MY_COMPANY,
        element: <MyCompanyView />,
        allowed: [],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={URL_PATHS.DASHBOARD} />,
  },
  // {
  //   path: '/*',
  //   element: <Navigate to={URL_PATHS.LOGIN} />,
  // },
]
