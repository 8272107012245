import { Box, Typography } from '@mui/material'
import {
  ClientContactForm,
  ClientUpdateForm,
  OvClientInvoicesTab,
  OvClientQuotationsTab,
} from 'src/partials/index.partials'

export const CLIENT_DETAIL_TABS = {
  CREATE: [
    {
      label: 'Klantgegevens',
      value: '1',
      component: <ClientUpdateForm />,
    },
    {
      label: 'Contactpersonen',
      value: '2',
      component: (
        <Box>
          <Typography>
            Je kunt contactpersonen toevoegen nadat je de klant hebt opgeslagen.
          </Typography>
        </Box>
      ),
    },
  ],
  EDIT: [
    {
      label: 'Klantgegevens',
      value: '1',
      component: <ClientUpdateForm />,
    },
    {
      label: 'Contactpersonen',
      value: '2',
      component: <ClientContactForm />,
    },
    {
      label: 'Offertes',
      value: '3',
      component: <OvClientQuotationsTab />,
    },
    {
      label: 'Facturen',
      value: '4',
      component: <OvClientInvoicesTab />,
    },
  ],
}
