export const KEYS = {
  HOME: 'home',
  BLOCKED: 'blocked',
  INVOICE_CREATE: 'invoice-create',
  INVOICE_LIST: 'invoice-list',
  INVOICE_SINGLE: 'invoice-single',
  CLIENT_LIST: 'client-list',
  CLIENT_DETAIL: 'client-detail',
  CLIENT_CREATE: 'client-create',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  MODAL: 'modal',
  SIGNUP: 'signup',
  PROFILE: 'profile',
  COMPANY: 'company',
  MY_COMPANY: 'my-company',
  GENERAL: 'general',
  LINES: 'lines',
  TERMS: 'terms',
  QUOTE_LIST: 'quotes-list',
  QUOTE_SINGLE: 'quotes-single',
  QUOTE_CREATE: 'quotes-create',
}

export const STORES = {
  AUTH: 'auth',
  CLIENT: 'client',
  COMPANY: 'company',
  DASHBOARD: 'dashboard',
  INVOICE: 'invoice',
  KVK: 'kvk',
  UI: 'ui',
  USER: 'user',
  QUOTATION: 'quotation',
}

export const STORE_KEYS = {
  COMPANY: 'company',
  COMPANIES: 'companies',
  COLUMNS: 'columns',
  COUNTRIES: 'countries',
  CLIENT_CONTACTS: 'clientContacts',
  CLIENT_CONTACT: 'clientContact',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  LOADING: 'loading',
  LIST: 'list',
  USER: 'user',
  SESSION_TOKEN: 'sessionToken',
  SINGLE: 'single',
  USER_ROLE: 'userRole',
  MENU_OPEN: 'menuOpen',
  MODAL: 'modal',
  MODAL_OPEN: 'modalOpen',
  ROWS: 'rows',
  SELECTED: 'selected',
  PAGINATION: 'pagination',
  TOKEN: 'token',
  TASKS: 'tasks',
  VAT_CODES: 'vat_codes',
}

export const FORM_KEYS = {
  USERNAME: 'username',
  PASSWORD: 'password',
}

export const EVENT_KEYS = {
  UNAUTH: 'unauth',
  SERVER_ERROR: 'serverError',
  LOGOUT: 'logout',
  NEW_TOKEN: 'newToken',
}
