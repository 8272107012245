import { PageWrapper } from '@components'
import { URL_PATHS } from '@constants'
import { useStore } from '@hooks/useStore'
import {
  Add,
  Check,
  Download,
  Edit,
  GetApp,
  Save,
  SwapHoriz,
} from '@mui/icons-material'
import InvoiceIcon from '@mui/icons-material/ReceiptLong'
import { Box, Button } from '@mui/material'
import { ISingleDocument } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'

import {
  UpdateInvoiceForm,
  UpdateQuotationForm,
} from 'src/partials/index.partials'

export const QuotationDetailView = observer(() => {
  const { ui, quotation, shared, client } = useStore()
  const [edit, setEdit] = useState(false)
  const [finalized, setFinalized] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()

  const getDefaultValues = async () => {
    return await quotation.getSingle(parseInt(id ?? ''))
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleBack = () => navigate('..')

  const handleSuccess = (newDocument: ISingleDocument) => {
    setEdit(false)
    form.reset(newDocument)
    ui.handleToaster('Wijzigingen opgeslagen', { type: 'success' })
  }

  const onSubmitCallback = async (values: FieldValues) => {
    await quotation
      .update(values)
      .then(res => {
        handleSuccess(res)
      })
      .catch(e => console.error(e))
  }

  const handleSubmit = () => form.handleSubmit(onSubmitCallback)()

  const handleCancel = () => {
    form.reset()
    setEdit(false)
  }

  const handleFinalize = async () => {
    await ui
      .confirm({
        title: 'Offerte definitief maken?',
        content:
          // eslint-disable-next-line max-len
          'Door akkoord te gaan maak je de offerte definitief. Je kan deze hierna niet meer wijzigen. Weet je zeker dat je de factuur definitief wilt maken?',
        withCancel: true,
      })
      .then(() => setFinalized(true))
      .catch(e => void 0)
  }

  useEffect(() => {
    quotation.getSingle(parseInt(id ?? ''))
    client.getAll()
    if (!shared.has_vat_codes) shared.getVatCodes()
  }, [])

  return (
    <OvFormProvider
      loading={quotation.loading}
      form={form}
      readOnly={!edit}
      onSubmit={handleSubmit}>
      <PageWrapper
        backAction={handleBack}
        pageTitle="Offerte"
        actions={
          <Box
            display="flex"
            gap={2}>
            {edit && (
              <Button
                variant="outlined"
                onClick={handleCancel}>
                Annuleren
              </Button>
            )}
            {!finalized && (
              <Button
                endIcon={edit ? <Save /> : <Edit />}
                variant={edit ? 'contained' : 'outlined'}
                onClick={edit ? handleSubmit : () => setEdit(true)}>
                {edit ? 'Offerte Opslaan' : 'Offerte bewerken'}
              </Button>
            )}
            {!edit && !finalized && (
              <Button
                variant="contained"
                endIcon={<Check />}
                onClick={handleFinalize}>
                Maak definitief
              </Button>
            )}
            {finalized && (
              <>
                <Button
                  variant="outlined"
                  endIcon={<Download />}>
                  Download
                </Button>
                <Button
                  variant="contained"
                  endIcon={<InvoiceIcon />}>
                  Maak factuur
                </Button>
              </>
            )}
          </Box>
        }>
        <UpdateQuotationForm />
      </PageWrapper>
    </OvFormProvider>
  )
})
