import ApiConfig from '@config/api.config'
import { EVENT_KEYS } from '@constants'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

export class BaseApi {
  api: AxiosInstance
  download: AxiosInstance
  upload: AxiosInstance
  kvk: AxiosInstance
  requests: AxiosRequestConfig[]
  controller: AbortController

  addInterceptor = (instance: AxiosInstance): void => {
    instance.interceptors.request.use(request => {
      request.signal = this.controller.signal
      return request
    })

    instance.interceptors.response.use(
      response => {
        const { authorization } = response.headers
        if (authorization) {
          const newTokenEvent = new CustomEvent(EVENT_KEYS.NEW_TOKEN, {
            detail: authorization,
          })
          window.dispatchEvent(newTokenEvent)
        }

        return response
      },
      error => {
        const { status } = error.response
        // Example for handling internal error
        if (status === 500) {
          const serverError = new Event(EVENT_KEYS.SERVER_ERROR)
          window.dispatchEvent(serverError)
        }

        // AccessToken fails
        if (status === 403) {
          const unauthEvent = new Event(EVENT_KEYS.UNAUTH)
          window.dispatchEvent(unauthEvent)
        }

        //RefreshToken fails
        if (status === 401) {
          const logoutEvent = new Event(EVENT_KEYS.LOGOUT)
          window.dispatchEvent(logoutEvent)
        }

        // Handle default error
        return Promise.reject(error.response.data)
      }
    )
  }

  constructor() {
    this.controller = new AbortController()
    const _api = axios.create(ApiConfig.api)
    this.addInterceptor(_api)
    this.api = _api
    this.kvk = axios.create(ApiConfig.kvk)
    this.download = axios.create(ApiConfig.download)
    this.upload = axios.create(ApiConfig.upload)
    this.requests = []
  }
}
