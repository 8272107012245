import { Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CreateInvoiceForm } from 'src/partials/index.partials'

export const NewInvoice = observer(() => {
  return (
    <>
      <Typography>
        Selecteer de klant waarvoor je een factuur wilt maken
      </Typography>
      <CreateInvoiceForm />
    </>
  )
})
