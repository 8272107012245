import { OvFormProvider, PageWrapper } from '@components'
import { CLIENT_DETAIL_TABS } from '@constants'
import { useStore } from '@hooks/useStore'
import { Delete, Edit, Save } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Tab } from '@mui/material'
import { ISingleClient } from '@typings'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export const ClientDetailView = observer(() => {
  const { client, shared, ui } = useStore()
  const [readOnly, setReadOnly] = useState(true)
  const [selectedTab, setSelectedTab] = useState(
    CLIENT_DETAIL_TABS.EDIT[0].value
  )

  const { id } = useParams()

  if (!id) {
    return null
  }
  const _id = parseInt(id)

  const navigate = useNavigate()

  const getDefaultValues = async () => {
    return await client.getSingle(_id)
  }

  const handleCancel = () => {
    form.reset()
    setReadOnly(true)
  }

  const form = useForm({
    defaultValues: getDefaultValues,
  })

  const handleTabChange = useCallback(
    async (e: React.SyntheticEvent<Element, Event>, val: string) => {
      // if (
      //   selectedTab === CLIENT_DETAIL_TABS[0].value &&
      //   form.formState.isDirty &&
      //   val === CLIENT_DETAIL_TABS[1].value
      // ) {
      //   await ui
      //     .confirm({
      //       title: 'Let op',
      //       content:
      //         'Er zijn wijzigingen die nog niet zijn opgeslagen. Wil je je wijzigingen bewaren?',
      //       confirmLabel: 'Ja, Bewaar',
      //       withCancel: true,
      //       cancelLabel: 'Nee',
      //     })
      //     .then(() => handleSubmit())
      //     .catch(() => {
      //       handleCancel()
      //     })
      // }
      setSelectedTab(val)
    },
    [form.formState.isDirty, selectedTab]
  )

  const onSubmitCallBack = (data: FieldValues) => {
    client.update(data as ISingleClient).then(res => handleSucces(res))
  }

  const handleSucces = (data: ISingleClient) => {
    toast('Klantgegevens opggeslagen', { type: 'success' })
    setReadOnly(true)
    form.reset(data)
  }

  const handleSubmit = () => {
    form.handleSubmit(onSubmitCallBack)()
  }

  const handleDeleteClient = async () => {
    client.remove()
  }

  const renderClientTabs = useMemo(
    () => (
      <>
        <TabList onChange={handleTabChange}>
          {CLIENT_DETAIL_TABS.EDIT.map(singleTab => (
            <Tab
              key={singleTab.value}
              value={singleTab.value}
              label={singleTab.label}
            />
          ))}
        </TabList>
        <Box>
          {CLIENT_DETAIL_TABS.EDIT.map(singleTab => (
            <TabPanel
              key={singleTab.value}
              sx={{ padding: '24px 0' }}
              value={singleTab.value}>
              {singleTab.component}
            </TabPanel>
          ))}
        </Box>
      </>
    ),
    [selectedTab, client.current_client, client.single, client.loading]
  )

  useEffect(() => {
    // client.getClientContacts(_id)
    if (!shared.has_countries) shared.getCountries()

    return () => client.reset()
  }, [_id])

  return (
    <OvFormProvider
      form={form}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      loading={client.loading}>
      <PageWrapper
        pageTitle={client.single.name}
        backAction={() => navigate('..')}
        actions={
          <Box
            display="flex"
            gap={2}>
            {!readOnly && (
              <Button
                variant="outlined"
                onClick={handleCancel}>
                Annuleren
              </Button>
            )}
            {readOnly && (
              <Button
                endIcon={<Delete />}
                variant="outlined"
                onClick={handleDeleteClient}>
                Klant verwijderen
              </Button>
            )}
            <Button
              endIcon={!readOnly ? <Save /> : <Edit />}
              variant="contained"
              onClick={!readOnly ? handleSubmit : () => setReadOnly(false)}>
              {!readOnly ? 'Klant Opslaan' : 'Klant bewerken'}
            </Button>
          </Box>
        }>
        <TabContext value={selectedTab}> {renderClientTabs}</TabContext>
      </PageWrapper>
    </OvFormProvider>
  )
})
