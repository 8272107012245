import { createContext, useContext } from 'react'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import { OvFormContext } from '../ov-form-provider/ov-form-provider'

export type IOvForm = {
  children: React.ReactNode
  layout?: 'masonry' | 'column'
  onSubmit?: SubmitHandler<Record<string, unknown>>
  onError?: () => undefined | void
}

export const OvFormErrorContext = createContext<Pick<IOvForm, 'onError'>>({
  onError: () => void 0,
})

export const OvForm = ({
  children,
  layout = 'masonry',
  onSubmit: customOnSubmit,
  onError = () => void 0,
}: IOvForm) => {
  const { handleSubmit } = useFormContext()
  const { onFormSubmit } = useContext(OvFormContext)

  const onSubmit: IOvForm['onSubmit'] = (data, event) => {
    onFormSubmit(data, event)
    customOnSubmit?.(data, event)
  }

  return (
    <div>
      <OvFormErrorContext.Provider value={{ onError }}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>{children}</form>
      </OvFormErrorContext.Provider>
    </div>
  )
}
