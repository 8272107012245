import {
  Collapse,
  FormControl,
  FormHelperText,
  InputAdornment,
  SelectProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { forwardRef, useMemo } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export interface IOvPriceInput extends TextFieldProps<'outlined'> {
  onChangeCallback?: (value: string | number) => void
  key?: any
  value?: any
  helperText?: string
  size?: SelectProps['size']
  readOnly?: boolean
  sx?: SelectProps['sx']
  onChange: (value: any) => void
}

export const OvPriceInput = ({
  error,
  helperText = '',
  onChangeCallback,
  key,
  label,
  value,
  size,
  readOnly,
  sx,
  variant = 'outlined',
  onChange,
  ...rest
}: IOvPriceInput) => {
  const handleChange = (newValue: any) => {
    const _value = newValue !== undefined ? newValue : 0
    onChange && onChange(_value)
    if (onChangeCallback) onChangeCallback(_value)
  }

  const generateInputId = useMemo(
    () => `input_${label}-component-${Math.random()}`,
    []
  )

  return (
    <FormControl
      variant={variant}
      error={error}
      size={size}
      sx={{ minWidth: { md: 130 }, width: '100%', ...sx }}>
      <NumericFormat
        value={value as any}
        decimalScale={2}
        customInput={TextField}
        decimalSeparator=","
        thousandSeparator="."
        onValueChange={e => handleChange(e.floatValue)}
        id={generateInputId}
        error={error}
        key={key}
        size={size}
        disabled={readOnly}
        label={label}
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>,
        }}
        {...(rest as any)}
      />
      {/* <TextField
        sx={sx}
        id={generateInputId}
        error={error}
        key={key}
        disabled={readOnly}
        value={value}
        onChange={e => handleChange(e.target.value)}
        label={label}
        InputProps={{
          // startAdornment: <InputAdornment position="start">€</InputAdornment>,
          inputComponent: NumericFormatCustom as any,
        }}
        {...rest}
      /> */}
      <Collapse in={!!helperText}>
        <FormHelperText>{helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  )
}
