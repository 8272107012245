import { useStore } from '@hooks/useStore'
import { Button } from '@mui/material'
import { ICompanyInvoice, ICompanyInvoiceTask, ICompanyTask } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { OvFormInput } from 'src/components/core/ov-form-input/ov-form-input'
import { OvFormProvider } from 'src/components/core/ov-form-provider/ov-form-provider'
import { OvForm } from 'src/components/core/ov-form/ov-form'

export interface ISingleTask {
  taskId: number
  quantity: number
  price: number
}

export interface ICreateInvoiceTaskForm {
  invoiceId: ICompanyInvoice['id']
}

export const CreateInvoiceTaskForm = observer(
  ({ invoiceId }: ICreateInvoiceTaskForm) => {
    const form = useForm<ICompanyInvoiceTask>({
      defaultValues: {
        label: '',
        price: 0,
        unit: '',
        vatCodeId: '',
        forCompany: false,
        quantity: 1,
      },
    })
    const { generic, invoice, company } = useStore()

    const onSubmitCallback = async (values: FieldValues) => {
      const { forCompany, ...rest } = values

      if (forCompany) {
        company
          .createTask(values as ICompanyTask)
          // .then(value =>
          //   toast(`Succesvol nieuwe taak aangemaakt met de naam "${value}"`)
          // )
          .catch(e => form.setError('label', { message: e?.response?.data }))
      }

      console.log('Values: ', rest)
      invoice.addTask(invoiceId, rest as ICompanyInvoiceTask)
    }

    const handleSubmit = () => form.handleSubmit(onSubmitCallback)()

    useEffect(() => {
      generic.getVatCodes()
    }, [])

    return (
      <OvFormProvider
        form={form}
        loading={generic.loading}
        onSubmit={handleSubmit}>
        <OvForm>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <OvFormInput
              name="label"
              label="Label"
              placeholder="Label"
              sx={{ minWidth: '100px' }}
            />
            <OvFormInput
              name="price"
              label="Prijs (ex BTW)"
              helperText="Bijv. per Uur, Dag, Stuk, Ochtend etc"
            />
            <OvFormInput
              name="unit"
              label="Per"
              helperText="Bijv. per Uur, Dag, Stuk, Ochtend etc"
            />
            <OvFormInput
              type="select"
              options={generic.vat_codes}
              label="BTW Tarief"
              valueKey="id"
              labelKey="label"
              name="vatCodeId"
              helperText="Selecteer het BTW tarief dat op deze taak van toepassing is"
            />
            <OvFormInput
              type="checkbox"
              name="forCompany"
              label="Sla op voor later gebruik"
            />
          </div>
        </OvForm>
        <Button
          onClick={handleSubmit}
          variant="contained"
          type="submit"
          sx={{ marginTop: '1.5rem' }}>
          Voeg taak toe
        </Button>
      </OvFormProvider>
    )
  }
)
