import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { action, computed, makeAutoObservable } from 'mobx'
import { IKvkSearchResponse } from 'src/typings/kvk.typings'

export type IKvkPagination = Omit<IKvkSearchResponse, 'resultaten'>
export class KvKStore {
  [key: string]: any
  loading: boolean
  selected: any
  searchTerm: string
  list: IKvkSearchResponse['resultaten']
  pagination: IKvkPagination

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.selected = null
    this.searchTerm = ''
    this.list = [] as IKvkSearchResponse['resultaten']
    this.pagination = {
      pagina: 0,
      aantal: 15,
      totaal: 0,
      vorige: '',
      volgende: '',
      links: [] as IKvkPagination['links'],
    } as IKvkPagination
  }

  @computed
  get current_params() {
    let params: Record<string, unknown> = {
      q: this.searchTerm,
    }

    if (this.pagination) {
      params = {
        ...params,
        pagina: this.pagination.pagina,
        aantal: this.pagination.aantal,
      }
    }

    return params
  }

  @computed
  get current_list(): IKvkSearchResponse['resultaten'] {
    return this.list
  }

  @action
  setSelectedRow = (row: any) => {
    this.set(STORE_KEYS.SELECTED, row)
  }

  @action
  resetSelectedRow = () => this.set(STORE_KEYS.SELECTED, null)

  @action
  handleNewPage = (page: number) => {
    if (!this.pagination.pagina) return
    this.pagination.pagina = page
    this.findCompany()
  }

  @action
  handleSearchTerm = (q: string) => {
    this.searchTerm = q
    this.findCompany()
  }

  @action
  findCompany = async () => {
    this.set(STORE_KEYS.LOADING, true)
    try {
      const { resultaten, meta, ...rest } =
        await this.store.api.kvk.searchCompany({
          ...this.current_params,
        })

      if (meta) {
        const { error } = meta
        console.log('error[0].omschrijving: ', error[0].omschrijving)
      }
      this.set(STORE_KEYS.LIST, [...resultaten])
      this.set(STORE_KEYS.PAGINATION, rest)
    } catch (e) {
      console.log('error: ', e)
    } finally {
      this.set(STORE_KEYS.LOADING, false)
    }
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
