import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks/useStore'
import { observer } from 'mobx-react-lite'
import { ReactNode, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useBeforeUnload, useBlocker } from 'react-router-dom'

export const OvFormNavigationBlocker = observer(
  ({ children }: { children: ReactNode }) => {
    const { ui } = useStore()
    const {
      formState: { isDirty },
    } = useFormContext()
    const navigationBlocker = useBlocker(isDirty)

    // useBeforeUnload(
    //   useCallback(
    //     event => {
    //       if (!isDirty) return

    //       const e = event || window.event
    //       e.preventDefault()
    //       if (e) e.returnValue = ''
    //       return ''
    //     },
    //     [isDirty]
    //   )
    // )

    const confirmLeavePage = useCallback(async () => {
      try {
        await ui.confirm({
          title: LABELS.CONFIRM_EXIT_PAGE_TITLE,
          content: LABELS.CONFIRM_EXIT_PAGE_TEXT,
          confirmLabel: LABELS.CONTINUE,
          withCancel: true,
        })
        return navigationBlocker?.proceed?.()
        // navigationBlocker.reset?.()
        // if (navigationBlocker.location) navigate(navigationBlocker.location)
      } catch {
        return navigationBlocker?.reset?.()
      }
    }, [isDirty])

    useEffect(() => {
      if (navigationBlocker.state === KEYS.BLOCKED && !isDirty) {
        return navigationBlocker.reset?.()
      }

      if (navigationBlocker.state === KEYS.BLOCKED && isDirty) {
        confirmLeavePage()
      }
    }, [isDirty, navigationBlocker.state])

    return <>{children}</>
  }
)
