import { OvFormInputReadOnly, OvInput, OvSelect } from '@components'
import { useContext, useEffect, useId, useState } from 'react'
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form'
import { OvFormContext } from '../ov-form-provider/ov-form-provider'
import { OvFormErrorContext } from '../ov-form/ov-form'

import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from '@mui/material'
import { IOvInput } from '../ov-input/ov-input'
import { IOvSelect } from '../ov-select/ov-select'
import { IOvPriceInput, OvPriceInput } from '../ov-price-input/ov-price-input'
import dayjs from 'dayjs'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'

type OmitId<T> = Omit<T, 'id'>

export type IOvFormInput<T extends FieldValues, I> = {
  id?: string
  label?: string
  name: keyof FieldValues
  readOnly?: boolean
  validate?: string
  loading?: boolean
  hideAsterisk?: boolean
  onError?: (arg: FieldError) => void
  withLabel?: boolean
} & UseControllerProps<T> &
  OmitId<I>

type IOvSelectInputProps = Parameters<typeof OvSelect>[0]

type IOvDateInputProps = DatePickerProps<any> & {
  type: 'date'
  readOnly?: boolean
}

type IOvCheckbox = Parameters<typeof FormControlLabel>[0] & {
  type: 'checkbox'
  label: string
  readonly?: boolean
} & Omit<FormControlLabelProps, 'onFocus' | 'label'>

// type IOvFormSelectProps = IOvSelect & {
//   type: 'select'
//   readonly?: boolean
//   options: IOvSelect['options']
// }

function OvFormInput<T extends FieldValues>(
  props: IOvFormInput<T, IOvSelectInputProps> & {
    type: 'select'
    readonly?: boolean
  }
): JSX.Element

function OvFormInput<T extends FieldValues>(
  props: IOvFormInput<T, IOvCheckbox> & {
    type: 'checkbox'
    readOnly?: boolean
  }
): JSX.Element

function OvFormInput<T extends FieldValues>(
  props: IOvFormInput<T, Omit<IOvInput, 'variant'>> & {
    readonly?: boolean
  }
): JSX.Element

function OvFormInput<T extends FieldValues>(
  props: IOvFormInput<T, IOvPriceInput> & {
    type: 'currency'
    readonly?: boolean
  }
): JSX.Element

function OvFormInput<T extends FieldValues>(
  props: IOvFormInput<T, IOvDateInputProps> & {
    type: 'date'
    readonly?: boolean
  }
): JSX.Element

function OvFormInput<T extends FieldValues>({
  rules,
  name,
  id: customId,
  label = '',
  readOnly: customReadOnly,
  loading: customLoading,
  onError: customOnError,
  validate,
  type = 'text',
  ...props
}: IOvFormInput<
  T,
  | IOvInput
  | IOvSelectInputProps
  | IOvCheckbox
  | IOvPriceInput
  | IOvDateInputProps
>) {
  const [error, setError] = useState<FieldError | undefined>()
  const { control, setValue, register } = useFormContext<T>()
  const formContext = useContext(OvFormContext)
  const formErrorContext = useContext(OvFormErrorContext)
  const id = customId || name + useId()

  const readOnly = customReadOnly || formContext?.readOnly
  const loading = customLoading || formContext?.loading
  const onError = customOnError || formErrorContext?.onError
  // const register = formContext?.registerInput

  useEffect(() => {
    register(name, { ...rules })
  }, [])

  useEffect(() => {
    error && onError?.(error)
  }, [error])

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={context => {
          switch (type) {
            case 'select': {
              const _props = props as IOvSelect
              const _readOnlyValue = _props.options.find(el =>
                _props.output === 'option'
                  ? el[_props.valueKey] === context.field.value[_props.valueKey]
                  : el[_props.valueKey] === context.field.value
              )

              return readOnly ? (
                <OvFormInputReadOnly
                  {...context.field}
                  {...props}
                  label={label}
                  value={_readOnlyValue ? _readOnlyValue[_props.labelKey] : ''}
                />
              ) : (
                <OvSelect
                  id={id}
                  readOnly={readOnly}
                  disabled={readOnly || loading}
                  error={!!context.fieldState.error}
                  helperText={context.fieldState.error?.message}
                  {...context.field}
                  {..._props}
                  label={label}
                />
              )
            }

            case 'checkbox': {
              return (
                <FormControlLabel
                  {...(props as Partial<FormControlLabelProps>)}
                  label={label}
                  {...context.field}
                  control={<Checkbox />}
                />
              )
            }

            case 'currency': {
              return readOnly ? (
                <OvFormInputReadOnly
                  {...context.field}
                  {...props}
                  label={label}
                  value={`€ ${context.field.value}`}
                />
              ) : (
                <OvPriceInput
                  id={id}
                  readOnly={readOnly}
                  disabled={readOnly || loading}
                  error={!!context.fieldState.error}
                  helperText={context.fieldState.error?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(props as any)}
                  {...context.field}
                  value={context.field.value}
                  label={label}
                />
              )
            }

            case 'date':
              return readOnly ? (
                <OvFormInputReadOnly
                  {...context.field}
                  {...props}
                  label={label}
                  value={dayjs(context.field.value).format('DD-MM-YYYY')}
                />
              ) : (
                <DatePicker
                  readOnly={readOnly}
                  disabled={readOnly || loading}
                  sx={{ width: '100%' }}
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      error: !!context.fieldState.error,
                      helperText: context.fieldState.error?.message,
                    },
                  }}
                  {...props}
                  {...context.field}
                  defaultValue={props.defaultValue}
                  value={dayjs(context.field.value)}
                  label={label}
                />
              )

            default:
              return readOnly ? (
                <OvFormInputReadOnly
                  {...context.field}
                  {...props}
                  label={label}
                  value={context.field.value}
                />
              ) : (
                <OvInput
                  type="text"
                  id={id}
                  inputRef={context.field.ref}
                  readOnly={readOnly}
                  disabled={readOnly || loading}
                  error={!!context.fieldState.error}
                  helperText={context.fieldState.error?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(props as OmitId<IOvInput>)}
                  {...context.field}
                  value={context.field.value}
                  label={label}
                />
              )
          }
        }}
      />
    </div>
  )
}

export { OvFormInput }
