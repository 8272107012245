import { FORM_KEYS, LABELS } from '@constants'

export const LOGIN_FORM = {
  USERNAME: {
    LABEL: LABELS.USERNAME,
    KEY: FORM_KEYS.USERNAME,
    REQUIRED: LABELS.USERNAME_REQUIRED,
  },
  PASSWORD: {
    LABEL: LABELS.PASSWORD,
    KEY: FORM_KEYS.PASSWORD,
    REQUIRED: LABELS.PASSWORD_REQUIRED,
  },
}
