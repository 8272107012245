import { LABELS } from '../../labels.constants'
import { URL_PATHS } from '../../routes.constants'

import DashobardIcon from '@mui/icons-material/Dashboard'
import MyBusinessIcon from '@mui/icons-material/Business'
import CustomerIcon from '@mui/icons-material/Group'
import InvoiceIcon from '@mui/icons-material/ReceiptLong'
import ProfileIcon from '@mui/icons-material/Person'
import QuoteIcon from '@mui/icons-material/TableView'

export const MAIN_PAGE_NAV = [
  {
    label: LABELS.DASHOARD,
    path: URL_PATHS.DASHBOARD,
    icon: DashobardIcon,
  },
  {
    label: LABELS.INVOICES,
    path: URL_PATHS.INVOICE_LIST,
    icon: InvoiceIcon,
  },
  {
    label: LABELS.QUOTES,
    path: URL_PATHS.QUOTE_LIST,
    icon: QuoteIcon,
  },
  {
    label: LABELS.CLIENTS,
    path: URL_PATHS.CLIENT_LIST,
    icon: CustomerIcon,
  },
]

export const MOBILE_MAIN_NAV = [
  {
    label: LABELS.INVOICES,
    path: URL_PATHS.INVOICE_LIST,
    icon: InvoiceIcon,
  },
  {
    label: LABELS.QUOTES,
    path: URL_PATHS.QUOTE_LIST,
    icon: QuoteIcon,
  },
  {
    label: LABELS.CLIENTS,
    path: URL_PATHS.CLIENT_LIST,
    icon: CustomerIcon,
  },
]

export const MAIN_MY_NAV = [
  {
    label: LABELS.MY_COMPANY,
    path: URL_PATHS.MY_COMPANY,
    icon: MyBusinessIcon,
  },
  {
    label: LABELS.MY_ACCOUNT,
    path: URL_PATHS.ACCOUNT,
    icon: ProfileIcon,
  },
]
