import { useStore } from '@hooks/useStore'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  CssVarsTheme,
  StyledEngineProvider,
} from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'src/styles/index.styles.scss'
import { OutvoiceRouter } from './components/core/index.core.component'
import { EVENT_KEYS } from './constants/keys.contants'
import { responsiveTheme } from './styles/theme/theme'
import { getFromStorage } from '@helpers/storage.helpers'

const App = observer(() => {
  const { auth } = useStore()
  useEffect(() => {
    window.addEventListener(EVENT_KEYS.NEW_TOKEN, (e: any) =>
      auth.refreshToken(e.detail)
    )
    // window.addEventListener(EVENT_KEYS.UNAUTH, () => auth.logout(true))
    window.addEventListener(EVENT_KEYS.LOGOUT, () => {
      const unauth = getFromStorage(EVENT_KEYS.UNAUTH)
      console.log('unauth: ', unauth)
      if (!unauth) auth.logout(true)
    })

    return () => {
      window.removeEventListener(EVENT_KEYS.NEW_TOKEN, (e: any) =>
        auth.refreshToken(e.detail)
      )
      // window.removeEventListener(EVENT_KEYS.UNAUTH, () => auth.logout(true))
      window.removeEventListener(EVENT_KEYS.LOGOUT, () => auth.logout(true))
    }
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssVarsProvider theme={responsiveTheme as unknown as CssVarsTheme}>
          <div className="App">
            <OutvoiceRouter />
          </div>
          <ToastContainer position="bottom-right" />
        </CssVarsProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  )
})

export default App
