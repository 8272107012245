import { Button } from '@mui/material'
import { DataGridProps, GridAlignment, GridColTypeDef } from '@mui/x-data-grid'
import { ISingleClient } from '@typings'
import { useNavigate } from 'react-router-dom'
import { formatValueToCurrency } from './formatCentToAmount.helper'

export interface IFormatColumnData {
  columns: DataGridProps['columns']
}

export const formatColumnData = (columns: DataGridProps['columns']) => {
  const renderClientCell = (client?: ISingleClient) => {
    const navigate = useNavigate()

    if (!client) return '-'

    return (
      <Button
        variant="text"
        onClick={() =>
          navigate(`/klanten/${client.id}`, {
            state: { from: 'datagrid' },
          })
        }>
        {client.name}
      </Button>
    )
  }

  const handleFormat = (type: GridColTypeDef['type']) => {
    switch (type) {
      case 'date':
      case 'dateTime':
        return {
          valueGetter: ({ value }: any) => {
            return value ? new Date(value) : undefined
          },
        }
      case 'currency':
        return {
          align: 'right' as GridAlignment,
          headerAlign: 'right' as GridAlignment,
          valueGetter: ({ value }: { value: number }) =>
            value ? formatValueToCurrency(value) : '-',
        }
      case 'client':
        return {
          renderCell: ({ value }: { value?: ISingleClient }) =>
            renderClientCell(value),
        }
      default:
        return {}
    }
  }

  return columns.map(singleColumn => {
    const formatted = {
      ...singleColumn,
      headerClassName: 'ov-datagrid-header',
      ...handleFormat(singleColumn.type),
    }

    return formatted
  })
}
