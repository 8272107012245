import { IDatagridResponsiveColumns } from '@typings'
import { STORES } from '../keys.contants'
import { LABELS, LABELS_ABBR } from '../labels.constants'

export const DATA_GRID_CUSTOM_COLUMNS: IDatagridResponsiveColumns = {
  CLIENT_INVOICES: {
    mobile: [
      {
        label: LABELS_ABBR.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        key: 'total',
        label: LABELS_ABBR.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
    default: [
      {
        label: LABELS.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.SUB_TOTAL,
        key: 'subtotal',
        flex: 1,
        type: 'currency',
      },
      {
        label: LABELS.VAT,
        key: 'vat',
        flex: 1,
        type: 'currency',
      },
      {
        key: 'total',
        label: LABELS.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
  },
  CLIENT_QUOTES: {
    mobile: [
      {
        label: LABELS_ABBR.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        key: 'total',
        label: LABELS_ABBR.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
    default: [
      {
        label: LABELS.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.SUB_TOTAL,
        key: 'subtotal',
        flex: 1,
        type: 'currency',
      },
      {
        label: LABELS.VAT,
        key: 'vat',
        flex: 1,
        type: 'currency',
      },
      {
        key: 'total',
        label: LABELS.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
  },
}

export const DATA_GRID_COLUMNS: IDatagridResponsiveColumns = {
  [STORES.INVOICE]: {
    mobile: [
      {
        label: LABELS_ABBR.INVOICE_NUMBER,
        key: 'number',
        flex: 2,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.CLIENT,
        key: 'client',
        flex: 1,
        type: 'client',
      },
      {
        key: 'total',
        label: LABELS_ABBR.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
    default: [
      {
        label: LABELS.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.CLIENT,
        key: 'client',
        flex: 2,
        type: 'client',
      },
      {
        label: LABELS.SUB_TOTAL,
        key: 'subtotal',
        flex: 1,
        type: 'currency',
      },
      {
        label: LABELS.VAT,
        key: 'vat',
        flex: 1,
        type: 'currency',
      },
      {
        key: 'total',
        label: LABELS.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
  },
  [STORES.QUOTATION]: {
    mobile: [
      {
        label: LABELS_ABBR.INVOICE_NUMBER,
        key: 'number',
        flex: 2,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.CLIENT,
        key: 'client',
        flex: 1,
        type: 'client',
      },
      {
        key: 'total',
        label: LABELS_ABBR.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
    default: [
      {
        label: LABELS.INVOICE_NUMBER,
        key: 'number',
        flex: 1,
      },
      {
        label: LABELS.DATE,
        type: 'date',
        key: 'issued_at',
        flex: 1,
      },
      {
        label: LABELS.CLIENT,
        key: 'client',
        flex: 2,
        type: 'client',
      },
      {
        label: LABELS.SUB_TOTAL,
        key: 'subtotal',
        flex: 1,
        type: 'currency',
      },
      {
        label: LABELS.VAT,
        key: 'vat',
        flex: 1,
        type: 'currency',
      },
      {
        key: 'total',
        label: LABELS.TOTAL,
        flex: 1,
        type: 'currency',
      },
    ],
  },
  [STORES.CLIENT]: {
    mobile: [
      {
        label: LABELS.CLIENT_NAME,
        key: 'name',
        flex: 2,
      },
    ],
    default: [
      {
        label: LABELS.CLIENT_NAME,
        key: 'name',
        flex: 2,
      },
      {
        label: LABELS.CITY,
        key: 'city',
        flex: 1,
      },
      {
        label: LABELS.COC,
        key: 'coc',
        flex: 1,
      },
      {
        label: LABELS.TAX_NUMBER,
        key: 'tax',
        flex: 1,
      },
    ],
  },
  [STORES.AUTH]: {},
  [STORES.COMPANY]: {},
  [STORES.DASHBOARD]: {},
  [STORES.KVK]: {},
  [STORES.UI]: {},
  [STORES.USER]: {},
}
