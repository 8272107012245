import { STORE_KEYS } from '@constants'
import { saveToStorage } from '@helpers/storage.helpers'
import { Store } from '@stores'
import { IUiModalConfirm, IUiModalGeneric } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import { ToastContent, ToastOptions } from 'react-toastify/dist/types'

export class UiStore {
  [key: string]: any
  menuOpen: boolean
  modal: IUiModalGeneric | IUiModalConfirm
  modalOpen: boolean
  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.modal = {} as UiStore['modal']
    this.modalOpen = false
    this.menuOpen = false
  }

  @computed
  get menu_open(): boolean {
    return this.menuOpen
  }

  @action
  toggleModal = () => {
    this.set(STORE_KEYS.MODAL_OPEN, !this.modalOpen)
  }

  @action
  showModal = async (data: Partial<IUiModalGeneric>) => {
    this.resetModal()
    await new Promise((resolve, reject) => {
      this.set(STORE_KEYS.MODAL, {
        ...data,
        type: 'modal',
        visible: true,
        closable: true,
        width: 'md',
        resolve,
        reject: () => {
          reject()
        },
      })
      this.set(STORE_KEYS.MODAL_OPEN, true)
    }).finally(() => this.closeModalGracefully())
  }

  @action
  async closeModalGracefully() {
    this.set(STORE_KEYS.MODAL_OPEN, false)
    if (this.modal.onClose) this.modal.onClose()
    return new Promise(resolve => {
      setTimeout(() => {
        this.resetModal()
        resolve(true)
      }, 300)
    })
  }

  @action
  handleToaster = (content: ToastContent<unknown>, options: ToastOptions) => {
    toast(content, options)
  }

  @action
  handleErrorToast = (message?: string) => {
    const _message =
      message ??
      'Er is helaas iets mis gegaan.'
    this.handleToaster(_message, {
      type: 'error',
    })
  }

  @action
  async confirm({
    title,
    content,
    confirmLabel,
    withCancel,
    cancelLabel,
    reject,
    resolve,
    modalOptions,
  }: Partial<IUiModalConfirm>) {
    this.resetModal()
    await new Promise((resolve, reject) => {
      this.set(STORE_KEYS.MODAL, {
        type: 'confirm',
        visible: true,
        closeable: true,
        width: 'md',
        title,
        confirmLabel,
        withCancel,
        cancelLabel,
        content,
        resolve,
        reject: () => {
          reject()
        },
        modalOptions,
      })
      this.set(STORE_KEYS.MODAL_OPEN, true)
    }).finally(() => this.closeModalGracefully())
  }

  @action
  resetModal = () => {
    this.set(STORE_KEYS.MODAL_OPEN, false)
    this.set(STORE_KEYS.MODAL, {})
  }

  @action
  toggleMenu = () => {
    this.set(STORE_KEYS.MENU_OPEN, !this.menuOpen)
  }

  @action
  set = (target: string, value: unknown, save?: boolean): unknown => {
    if (!target) return
    this[target] = value
    if (save) saveToStorage(target, value)
  }
}
